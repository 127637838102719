'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

let checkClaim = async function(groupId, index) {
  if (!index) {
    return true;
  }
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let merklePreSaleAddress = config.merklePreSaleAddress[networkId];
  let merklePreSaleContract = new ethers.Contract(
    merklePreSaleAddress,
    config.merklePreSaleABI,
    signer
  );
  let purchasedStatus = await merklePreSaleContract.isPurchased(groupId, index);

  return purchasedStatus;
};

let getMerkleData = async function() {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let userData;
  let proofList = config.whitelistProofs.leaves;
  for (let listEntry of proofList) {
    if (listEntry.address.toLowerCase() == address.toLowerCase()) {
      userData = listEntry;
    }
  }
  console.log('userData', userData);
  let merkleData = {
    userProofs: userData ? userData.proof : [],
    index: userData ? userData.index : null
  };
  return merkleData;
};

let whitelistedPurchaseItem = async function(
  poolId,
  groupId,
  amount,
  userIndex,
  proofs,
  dispatch
) {
  console.log(
    'whitelistedPurchaseItem',
    poolId,
    groupId,
    amount,
    userIndex,
    proofs
  );
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let merklePreSaleAddress = config.merklePreSaleAddress[networkId];
  let merklePreSaleContract = new ethers.Contract(
    merklePreSaleAddress,
    config.merklePreSaleABI,
    signer
  );
  let price = await merklePreSaleContract.price();
  let totalSpend = price.mul(amount);
  console.log("check", 1, userIndex, address, amount, proofs);
  let redemption = await merklePreSaleContract
    .connect(signer)
    .purchase(1, userIndex, address, amount, proofs, { value: totalSpend });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: redemption.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await redemption.wait();

  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      duration: 10000
    },
    { root: true }
  );

  await dispatch('mint/getBucks', '', { root: true });
  await dispatch('windows/close', { label: '' }, { root: true });
  setTimeout(async () => {
    await dispatch('windows/open', { label: 'Member' }, { root: true });
  }, 2000);
};

// Export the merkle service functions.
export const merkleService = {
  checkClaim,
  getMerkleData,
  whitelistedPurchaseItem
};
