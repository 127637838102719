<template>
  <div class="calendar">
    <div class="left">
      <div class="titleWrapper">
        <div class="title">
          <span>{{ monthName }} {{ dayNumber }},</span> {{ date.getFullYear() }}
        </div>
        <div class="date">{{ dayName }}</div>
        <div class="roadmap">Select An Event Below</div>
      </div>

      <div class="eventSection">
        <div class="timeInterval" v-for="index in 10" :key="index">
          {{ index * 10 }}%
        </div>

        <CalendarEvent
          v-for="(event, index) in calendarEvents"
          :key="event"
          class="timeSlot"
          :class="eventClasses[index]"
          :data="event"
          @click="toggleDetails(index)"
          :isClicked="currentDetailsIndex === index"
        />
      </div>
    </div>
    <div class="right">
      <div v-if="currentDetailsIndex == null" class="noEvents">
        No Event Selected
      </div>
      <CalendarDetails v-else :data="calendarEvents[currentDetailsIndex]" />
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import CalendarEvent from './calendar/CalendarEvent.vue';
import CalendarDetails from './calendar/CalendarDetails.vue';

export default {
  components: {
    CalendarEvent,
    CalendarDetails
  },

  setup() {
    const date = ref(new Date());
    const dayName = ref('');
    const monthName = ref('');
    const dayNumber = ref('');
    const getDate = () => {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      console.log(day);
      date.value = new Date(year + 978, month, day);

      let days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];

      dayName.value = days[date.value.getDay()];
      monthName.value = monthNames[date.value.getMonth()];
      dayNumber.value = day;
    };

    onMounted(() => {
      getDate();
    });

    // Calendar Events
    const currentDetailsIndex = ref(null);
    const eventClasses = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth'
    ];
    const calendarEvents = [
      {
        title: 'Deploy these NFTs to the year 2021',
        color: '232, 27, 86',
        percentage: '0%',
        description: [
          'The beginning of the Bootleg Buck Mob.',
          'These NFTs will be for people interested in joining me & becoming part of the mob',
          "Minting will be available in my computer via the portal-- I'll have a Google Chrome shortcut to the site."
        ],
        location: 'The Metaverse 🪐',
        guestList: 'Open Invite'
      },

      {
        title: 'Get my shit together for some scavenger hunts.',
        color: '234, 118, 15',

        percentage: '50%',
        description: [
          'I have so many ideas for this web portal.',
          "I'm tryna do some scavenger hunts on the site for members of the mob that support the vision",
          'Not really sure what the prizes will be yet, but I do have a few drawings that have been laying around 🤔'
        ],
        location: 'Earth, 2021 🌎',
        guestList: 'Bootleg Buck Mob 🦌'
      },

      {
        title: 'MOBTAPE VOL I.',
        color: '255, 191, 0',
        percentage: '30%',
        description: [
          "What I'm trying to do is feed an audience that is on the same wavelength.",
          "Feel like it'd be a good idea to start a series of playlist mixtapes for the mob.",
          "This will basically be what I'm currently listening to. Users that want to have insight into my rotation will be able to.",
          "We're all gonna be bumpin' the MOBTAPE VOL I, and future MOBTAPES will be collaboratively curated on discord."
        ],
        location: 'Spotify 🎶',
        guestList: 'Bootleg Buck Mob 🦌'
      },

      {
        title: 'Head over to the studio to get some designs in motion',
        color: '3, 220, 58',
        percentage: '70%',
        description: [
          'My last streetwear brand had a pretty tight budget.',
          'Might be cool to get back into designing clothes...',
          "Gonna go knock on some doors to see who's down to collab with a streetwear designer from the future."
        ],
        location: 'Los Angeles, 2021 📸',
        guestList: 'Bootleg Buck Mob 🦌  / ???'
      },

      {
        title:
          'Brainstorm with the community to see what we want to do next...',
        color: '49, 127, 255',
        percentage: '100%',
        description: [
          'Everything above is just the beginning.',
          'After all of that is done, we will be collaboratively deciding what we want to do next.',
          "I'm really excited to create content for the mob, and I want to go in a direction that is dope for everyone."
        ],
        location: 'Across the GLOBE 🤘',
        guestList: 'Bootleg Buck Mob 🦌'
      }
    ];

    const toggleDetails = index => {
      currentDetailsIndex.value = index;
    };

    return {
      date,
      dayName,
      monthName,
      dayNumber,
      calendarEvents,
      eventClasses,
      toggleDetails,
      currentDetailsIndex
    };
  }
};
</script>
<style scoped lang="scss">
.calendar {
  display: grid;
  grid-template-columns: minmax(400px, 100%) minmax(400px, 600px);
  height: 100%;
  .left {
    position: relative;
    overflow: scroll;
    background-color: rgba(37, 37, 37, 0.9);
    .titleWrapper {
      padding-bottom: 0px;
      background-color: rgba(37, 37, 37, 0.9);

      .title {
        padding: 20px;
        padding-bottom: 0px;
        font-size: 30px;
        font-weight: lighter;

        span {
          font-weight: bold;
        }
      }
      .date {
        padding: 20px;
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 20px;
      }

      .roadmap {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px 0px;
        text-align: center;
      }
    }

    .eventSection {
      padding: 10px;
      max-height: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;
      padding-bottom: 100px;

      .timeInterval {
        font-size: 10px;
        font-weight: lighter;
        opacity: 0.4;
        padding-top: 70px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        padding-bottom: 10px;
      }

      .timeSlot {
        position: absolute;
        margin-left: 30px;
        width: calc(100% - 30px);
        cursor: pointer;
        top: 0;
      }

      .first {
        top: 100px;
      }

      .second {
        top: 286px;
      }

      .third {
        top: 470px;
      }

      .fourth {
        top: 660px;
      }

      .fifth {
        top: 850px;
      }
      .sixth {
        top: 1100px;
      }
    }
  }

  .right {
    min-width: 400px;
    .noEvents {
      padding-top: 50%;
      padding-bottom: 50%;
      text-align: center;
      font-size: 20px;
      font-weight: lighter;
      opacity: 0.5;
    }
    background-color: rgba(0, 0, 0, 0.6);
    .calendarView {
      .calendar {
      }
    }
  }
}
</style>
