<template>
  <div class="mint">
    <div class="navbar">
      <div class="button">
        <img :src="require(`@/images/arrow.png`)" />
      </div>
      <div class="button reverse">
        <img :src="require(`@/images/arrow.png`)" />
      </div>

      <div class="url">
        <p>https://mint-a-buck.com/?creator=shad0e</p>
      </div>

      <div class="button">
        <img :src="require(`@/images/metamask-icon.png`)" />
      </div>
    </div>
    <div class="page">
      <transition name="slide" appear>
        <div>
          <img class="logo" :src="require(`@/images/mint-page-logo.png`)" />
          <h3>
            Welcome to SHAD0E's Minting page for his "Bootleg Buck Mob" NFTs. 🎉
          </h3>
          <p>Logged in as {{ store.state.ethers.address }}</p>
        </div>
      </transition>
      <transition name="slide" appear>
        <div v-if="showMint" class="buyArea">
          <DropdownMenu
            class="dropdown"
            :selection="number"
            :items="numbers"
            @selected="handleSelection(index, $event)"
          />
          <button @click="purchaseItem">Mint</button>
        </div>
      </transition>

      <transition name="slide" appear>
        <div class="mintWrapper">
          <Countdown
            v-if="!showMint"
            :startDate="startDate"
            :endDate="new Date(2023, 1, 1)"
          />
        </div>
      </transition>
      <transition name="slide" appear>
        <div>
          <div class="socialWrapper">
            <TwitterIcon
              class="icon"
              @click="navigateTo('https://twitter.com/bootlegbuckmob')"
            />
            <DiscordIcon
              class="icon"
              @click="navigateTo('https://discord.gg/UfdenFzyjd')"
            />
          </div>
          <p>
            <em
              >Note: ownership will be transferred to the specified address on
              the Ethereum Blockchain in the year 2021.
            </em>
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import DropdownMenu from '/src/components/ui/MintPageDropdownMenu.vue';
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import Countdown from '/src/components/ui/Countdown.vue';

export default {
  components: {
    DropdownMenu,
    TwitterIcon,
    DiscordIcon,
    Countdown
  },
  setup() {
    const store = useStore();
    const number = ref(1);
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const handleSelection = (index, selection) => {
      number.value = selection;
    };

    const startDate = new Date(2021, 9, 5, 12);
    const showMint = ref(false);

    onMounted(() => {
      if (new Date() > startDate) {
        showMint.value = true;
      }
      setInterval(() => {
        if (new Date() > startDate) {
          showMint.value = true;
        }
      }, 1000);
    });

    const purchaseItem = async () => {
      await store.dispatch(
        'mint/purchaseItem',
        {
          poolId: 0,
          groupId: 1,
          assetId: 0,
          amount: number.value
        },
        { root: true }
      );
    };

    const navigateTo = url => {
      window.open(url, '_blank');
    };

    return {
      store,
      numbers,
      number,
      handleSelection,
      purchaseItem,
      startDate,
      showMint,
      navigateTo
    };
  }
};
</script>
<style scoped lang="scss">
.mint {
  height: 100%;
  .navbar {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    padding: 10px 10px;

    .button {
      border-radius: 50%;
      transition: all 200ms ease-in;
      height: 35px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 15px;
        width: 15px;
        object-fit: contain;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    .reverse {
      transform: rotate(180deg);
      margin-left: 10px;
    }

    .url {
      margin: 0px 10px;
      width: 100%;
      border-radius: 50px;
      background-color: rgba(255, 255, 255, 0.1);
      height: 35px;
      display: flex;
      align-items: center;

      p {
        font-weight: normal;
        margin-left: 20px;
        // opacity: 0.7;
      }
    }
  }

  .page {
    overflow: scroll;

    padding: 40px 20px;
    padding-bottom: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: black;
    text-align: center;

    .socialWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      padding: 30px;

      .icon {
        transition: all ease-in 100ms;
        cursor: pointer;
        height: 30px;
        width: 30px;
        &:hover {
          color: #00ff42;
          transform: scale(1.1);
        }

        &:active {
          color: hotpink;
        }
      }
    }

    .logo {
      width: 50%;
      max-width: 300px;
      height: auto;
      object-fit: contain;
      margin-bottom: 20px;
    }

    h1 {
      margin-bottom: 20px;
    }

    h3 {
      margin-bottom: 20px;
      color: #00ff42;
      text-shadow: 0px 0px 10px #00ff42;
    }

    p {
      // opacity: 0.7;
      font-weight: normal;
      max-width: 60ch;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 0px;
      color: #00ff42;
      text-shadow: 0px 0px 10px #00ff42;

      em {
        font-size: 12px;
      }
    }

    .buyArea {
      width: 20%;
      display: flex;
      margin: 50px 0px;

      .dropdown {
        width: 100%;
        margin-right: 20px;
      }

      button {
        width: 100px;
        color: #00ff42;
        outline: none;
        cursor: pointer;
        border: 3px solid #00ff42;
        background: none;
        text-transform: uppercase;
        transition: all 200ms ease-in;

        &:hover {
          background-color: #00ff42;
          color: black;
        }
      }
    }

    .mintWrapper {
      padding: 20px;

      p {
        color: white;
        text-shadow: 0px 0px 5px white;
        font-style: italic;
        font-size: 18px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 500ms ease-out;
  transform: translateY(0px);
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
