<template>
  <div class="provenance">
    <div class="tabs">
      <div
        class="tab"
        :class="currentTab === 'info' ? 'currentTab' : ''"
        @click="toggleTab('info')"
      >
        Info
      </div>
      <!-- TODO: Uncomment and fill this in. -->
      <!-- <div
        class="tab"
        :class="currentTab === 'record' ? 'currentTab' : ''"
        @click="toggleTab('record')"
      >
        Provenance Record
      </div> -->
    </div>
    <div class="tabPane">
      <div class="info" v-if="currentTab === 'info'">
        <h1>Bootleg Buck Mob Provenance Spreadsheet</h1>
        <p>
          This sheet presents the provenance record of each Bootleg Buck that
          will ever exist. Each image from the shoot is firstly hashed using
          SHA-256 algorithm. A combined string is obtained by concatenating
          SHA-256 of each image in the specific order as listed below. The final
          proof is obtained by SHA-256 hashing this combined string. This is the
          final provenance record stored on the smart contract.
        </p>
        <div class="linebreak" />

        <p class="item">
          Once all clones have been minted, this spreadsheets application will
          be updated with the provenance record before the reveal to prove the
          randomness of the selection.
        </p>

        <!-- <h2>Important Info</h2>
        <div class="row">
          <p>Bootleg Buck Mob Smart Contract Address:</p>
          <p class="link">0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D</p>
        </div>
        <div class="row">
          <p>Randomization Smart Contract Address:</p>
          <p class="link">0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D</p>
        </div>
        <div class="row">
          <p>Final Proof Hash</p>
          <p>0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D</p>
        </div>
        <div class="row">
          <p>Final Starting Index</p>
          <p>6000</p>
        </div> -->
      </div>
      <div class="provenance" v-if="currentTab === 'record'">
        <div class="table">
          <div class="header">
            <div class="item">Initial Index</div>
            <div class="item">Rand Index</div>
            <div class="item">SHA-256 Hash</div>
            <div class="item">IPFS Hash</div>
          </div>

          <div class="row" v-for="index in 100" :key="index">
            <div class="item">{{ index }}</div>
            <div class="item">{{ index + 111 }}</div>
            <div class="item">
              c92b0386542d8beb8fb167aed611e73c5f30250a083f54edf78653946a8a6913
            </div>
            <div class="item hash">
              QmPe3mS97SfHqWTCA47zQ4KXXU6yTcEXMeiXeV14GgW79x
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
  setup() {
    const currentTab = ref('info');

    const toggleTab = value => {
      currentTab.value = value;
    };

    return {
      currentTab,
      toggleTab
    };
  }
};
</script>
<style scoped lang="scss">
.provenance {
  background-color: rgb(245, 245, 245);
  height: 100%;
  color: rgb(63, 63, 63);
  .tabs {
    display: flex;
    .tab {
      width: 100%;
      text-align: center;
      padding: 20px;
      transition: all 50ms ease-in;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .currentTab {
      border-bottom: 3px solid rgb(21, 134, 21);
      color: rgb(21, 134, 21);
    }
  }

  .tabPane {
    overflow: scroll;
    height: 100%;
    .info {
      padding: 20px;

      h1 {
        margin-bottom: 20px;
      }

      .linebreak {
        width: 100%;
        margin: 20px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .item {
        padding-bottom: 15px;
        font-weight: bold;
        text-align: center;
        color: red;
      }

      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
        padding: 5px;
        overflow: hidden;

        .link {
          cursor: pointer;
          font-weight: bold;
          color: blue;
          &:hover {
            text-decoration: underline;
          }

          &:active {
            color: red;
            text-decoration: none;
          }
        }
      }
    }

    .provenance {
      .table {
        .header {
          display: grid;
          width: 100%;
          grid-template-columns: 50px 50px 1fr 1fr;
          .item {
            background-color: rgba(0, 0, 0, 0.07);
            font-size: 12px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            color: rgba(0, 0, 0, 0.6);
            font-weight: normal;
            height: 100%;
            width: 100%;
            text-align: center;
            padding: 10px;
          }
        }

        .row {
          display: grid;
          grid-template-columns: 50px 50px 1fr 1fr;

          .item {
            font-size: 12px;
            height: 100%;
            overflow: hidden;
            text-align: left;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 5px;
          }

          .hash {
            cursor: pointer;
            font-weight: bold;
            color: blue;
            &:hover {
              text-decoration: underline;
            }

            &:active {
              color: red;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
</style>
