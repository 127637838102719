<template>
  <div>
    <div class="header">
      <img src="../../../images/buck-mob-logo.svg" />
      <!-- <p>BOOTLEG BUCK MOB</p> -->
    </div>

    <!-- <p class="description">
      An NFT collection used to access the next dimmension of streetwear.
    </p> -->

    <div class="tvMonitor">
      <img
        class="monitor"
        :src="require(`@/images/monitors/mobile-monitor.svg`)"
      />
      <div class="imageContainer">
        <img :src="require(`@/images/mobile-tv.gif`)" />
      </div>
    </div>
    <div class="countdownWrapper">
      <p class="text text-large">PORTAL LIVE NOW!</p>
      <p class="text text-large">ACCESS ON DESKTOP</p>
      <!-- <Countdown
        :startDate="new Date(2021, 9, 6)"
        :endDate="new Date(2023, 1, 1)"
      /> -->
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import Countdown from '/src/components/ui/Countdown.vue';

export default {
  props: {
    color: String
  },

  components: {
    // Countdown
  },

  setup(props) {}
};
</script>
<style scoped lang="scss">
.description {
  padding: 30px 30px 0px 30px;
  margin-bottom: -20px;

  text-align: center;
  text-transform: uppercase;
  font-style: italic;
  text-shadow: 0 0 10px white;
}

.header {
  display: flex;
  justify-content: center;

  img {
    max-width: 450px;
    width: 90%;
    height: 90%;
    box-sizing: border-box;
    object-fit: contain;
    // border: 5px solid;
    border-radius: 10px;
    // padding: 10px;
    padding: 0px 20px;
    margin-bottom: -40px;
    font-family: PressStart;
    // animation-name: colorChange, colorChange1;
    animation-duration: 5s, 6s;
    animation-iteration-count: infinite;
  }
  p {
    font-size: 5vw;
    color: white;
    border: 2px solid;
    border-radius: 10px;
    padding: 20px;
    font-family: PressStart;
    animation-name: colorChange, colorChange1;
    animation-duration: 5s, 6s;
    animation-iteration-count: infinite;

    @keyframes colorChange1 {
      0% {
        color: #e81b56;
        text-shadow: 0 0 5px #e81b56;
      }

      16% {
        color: #ea760f;
        text-shadow: 0 0 5px #ea760f;
      }

      32% {
        color: #ffbf00;
        text-shadow: 0 0 5px #ffbf00;
      }

      48% {
        color: #21dc3a;
        text-shadow: 0 0 5px #21dc3a;
      }

      64% {
        color: #317fff;
        text-shadow: 0 0 5px #317fff;
      }

      80% {
        color: #8b32f4;
        text-shadow: 0 0 5px #8b32f4;
      }

      100% {
        color: #e81b56;
        text-shadow: 0 0 5px #e81b56;
      }
    }

    @keyframes colorChange {
      0% {
        border-color: #e81b56;
        box-shadow: 0 0 5px #e81b56;
      }

      16% {
        border-color: #ea760f;
        box-shadow: 0 0 5px #ea760f;
      }

      32% {
        border-color: #ffbf00;
        box-shadow: 0 0 5px #ffbf00;
      }

      48% {
        border-color: #21dc3a;
        box-shadow: 0 0 5px #21dc3a;
      }

      64% {
        border-color: #317fff;
        box-shadow: 0 0 5px #317fff;
      }

      80% {
        border-color: #8b32f4;
        box-shadow: 0 0 5px #8b32f4;
      }

      100% {
        border-color: #e81b56;
        box-shadow: 0 0 5px #e81b56;
      }
    }
  }
}
.tvMonitor {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .monitor {
    z-index: 2;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .imageContainer {
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      left: 50%;
      top: 46%;
      transform: translate(-50%, -50%);
      height: 79%;
      width: 79%;
      object-fit: contain;
    }
  }
}

.countdownWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 20px;
    text-shadow: 0px 0px 5px white;
    margin-bottom: 10px;
    font-family: PressStart;
  }
}
</style>
