<template>
  <div class="about">
    <animated-component animationType="left">
      <h1 class="header">About</h1>
    </animated-component>
    <div class="aboutWrapper">
      <!-- <div class="messageWrapper">
        <div class="message twitter">
          <TwitterIcon class="icon" />
        </div>
        <div class="message discord">
          <DiscordIcon class="icon" />
        </div>
      </div> -->
      <animated-component>
        <div class="imageWrapper">
          <p>
            The Mob is comprised of <span>5,555</span> Unique clones of
            <span>SHAD0E</span>. each unique digital art piece serves as a key
            to gaining first access to the
            <span>members only (mob) content</span>, part of a larger community
            interested in <span>art + streetwear</span>. the plan is for shad0e
            to cultivate a community of
            <span>like-minded homies in the year 2021</span> to help him
            influence the art scene in the <span>future</span>.
          </p>
          <p>
            Each Buck was randomly generated with SHAD0E's stolen cloning
            machine and dressed in his streetwear collection of
            <span>vintage bootleg clothes</span>.
          </p>
        </div>
      </animated-component>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  setup() {
    return {};
  }
};
</script>
<style scoped lang="scss">
.about {
  margin: auto;
  max-width: 1100px;
  .header {
    font-family: CooperBlackOutline;
    font-size: 50px;
    font-weight: lighter;
    color: rgb(0, 255, 234);
    text-shadow: 0 0 5px rgb(0, 255, 234);
    text-align: left;
    margin-bottom: 20px;
  }

  .aboutWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 250px;
      width: 250px;
      object-fit: contain;
    }

    .imageWrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      text-align: left;
      font-size: 20px;
      text-transform: uppercase;
      font-style: italic;
      color: white;
      text-shadow: 0 0 5px white;

      span {
        color: rgb(255, 29, 161);
        text-shadow: 0 0 5px rgb(255, 29, 161);
      }
    }

    .messageWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 50%;

      .name {
        text-align: center;
        color: lime;
        text-shadow: 0 0 5px lime;
        margin-top: 20px;
      }
      img {
        border: 1px solid rgb(255, 196, 0);
        box-shadow: 0 0 15px rgb(255, 196, 0);
        // padding: 10px;
        height: 200px;
        width: 200px;
        border-radius: 10px;
      }
      .message {
        border: 3px solid white;
        box-shadow: 0 0 10px white;
        border-radius: 5px;
        padding: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;

        p {
          font-size: 20px;
          margin-bottom: 20px;
        }

        .icon {
          height: 70px;
          width: 70px;
        }

        .traitRow {
          display: flex;
          border-bottom: 2px dotted white;
          justify-content: space-between;
          font-size: 20px;
          font-weight: normal;
          text-align: left;
          // font-style: italic;
          // font-family: CooperBlack;
          color: white;
          text-shadow: 0 0 5px white;
          padding: 10px 0px;
          margin-bottom: 10px;

          .label {
            font-style: italic;
            text-transform: uppercase;
          }

          .value {
            font-size: 24px;
            font-family: PressStart;
            animation-duration: 10s;
            animation-name: colorChange1;
            animation-iteration-count: infinite;
            @keyframes colorChange1 {
              0% {
                color: #e81b56;
                text-shadow: 0 0 5px #e81b56;
              }

              16% {
                color: #ea760f;
                text-shadow: 0 0 5px #ea760f;
              }

              32% {
                color: #ffbf00;
                text-shadow: 0 0 5px #ffbf00;
              }

              48% {
                color: #21dc3a;
                text-shadow: 0 0 5px #21dc3a;
              }

              64% {
                color: #317fff;
                text-shadow: 0 0 5px #317fff;
              }

              80% {
                color: #8b32f4;
                text-shadow: 0 0 5px #8b32f4;
              }

              100% {
                color: #e81b56;
                text-shadow: 0 0 5px #e81b56;
              }
            }
          }

          .text-0 {
            color: rgb(255, 0, 0);
          }
        }
        .subtext {
          font-size: 20px;
          font-weight: normal;
          text-align: left;
          // font-style: italic;
          // font-family: CooperBlack;
          color: white;
          text-shadow: 0 0 5px white;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .about {
    .header {
      text-align: center;
      margin-bottom: 30px;
    }
    .aboutWrapper {
      .imageWrapper {
        text-align: center;
      }

      .messageWrapper {
        padding: 0px;
        .message {
          margin: 30px;
        }
      }
    }
  }
}
</style>
