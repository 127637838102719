<template lang="">
  <div class="manifesto" ref="terminal">
    <VueWriter
      v-for="(item, index) in loadingLogs"
      :key="index"
      :array="item"
      :typeSpeed="5"
      :eraseSpeed="99999"
      class="intro"
      :start="800 * index"
    />

    <div class="welcome" v-if="introEnded">
      <p>==================================</p>
      <p>Welcome to the Bootleg Buck Mob 🔥</p>
      <p>==================================</p>
      <div class="ascii">
        <div v-for="index in 3" :key="index">
          <p>\|/&nbsp;&nbsp;&nbsp;&nbsp;\|/</p>
          <p>&nbsp;&nbsp;\&nbsp;&nbsp;&nbsp;&nbsp;/</p>
          <p>&nbsp;&nbsp;&nbsp;\_/&nbsp;&nbsp;___&nbsp;&nbsp;&nbsp;___</p>
          <p>
            &nbsp;&nbsp;&nbsp;o&nbsp;o-'&nbsp;&nbsp;&nbsp;'''&nbsp;&nbsp;&nbsp;'
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;O&nbsp;-.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|\
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;|'''|&nbsp;|
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;|&nbsp;|
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;&nbsp;||
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          </p>
        </div>
      </div>
      <p>==================================</p>
    </div>

    <VueWriter
      v-for="(item, index) in textArray"
      :key="index"
      :array="item"
      :typeSpeed="5"
      :eraseSpeed="99999"
      class="writer"
    />

    <p class="text" v-if="introEnded && inProgress">
      [Press Enter To Continue]
    </p>
    <p class="text red" v-if="introEnded && !inProgress">
      [Server Offline]
    </p>
  </div>
</template>
<script>
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const introEnded = ref(false);

    const terminal = ref(null);

    const userName = () => {
      return store.state.ethers.address === undefined
        ? 'Guest'
        : store.state.ethers.address;
    };

    const arr = [
      [`>> Welcome User: ${userName()}          `],
      [
        '>> My name is SHAD0E, and I am an artist + vintage streetwear designer from the future              '
      ],
      [
        '>> In 2999, where I’m from, self expression is a thing of the past.                '
      ],

      [
        ">> I can't just draw whatever I want without facing serious repercussions...                  "
      ],

      [
        '>> So I want to showcase my artwork to an audience in your time, since my creations will never be seen in 2999.                  '
      ],
      [
        ">> I'll spare you the details, but I stole a shitty cloning machine and cloned myself 5,555 times                 "
      ],
      [
        '>> My clones and I did a photoshoot with some designs I made and my top secret personal vintage collection of bootleg clothes                '
      ],
      [
        '>> If you’re reading this, I’ve given you access to my computer so that you can mint NFTs from the shoot                '
      ],
      [
        '>> These NFTs will unlock content on this computer + all the things I hope to create in the future                  '
      ],
      [
        '>> Minting will be live after I retroactively deploy my contracts to the Ethereum blockchain in 2021, see you then...             '
      ]
    ];

    const getDate = () => {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      return new Date(year + 978, month, day);
    };

    const loadingLogs = [
      ['Booting up...'],
      [`Attempting connection from ${userName()} to remote server... `],
      [`Initializing time portal from local date: ${new Date()}`],
      [`To target remote date targeted at: ${getDate()}`],
      ['ssh -p 24601 shad0e@192.158.1.38'],
      ['Establishing... '],
      ['Connection Successful. ']
    ];

    onMounted(() => {
      document.addEventListener('keypress', e => {
        if (e.code === 'Enter') {
          addLine();
          scrollToBottom();
        }
      });

      setTimeout(() => {
        introEnded.value = true;
      }, 800 * loadingLogs.length);
    });

    const inProgress = computed(() => {
      return textArray.value.length < arr.length;
    });

    const addLine = () => {
      if (textArray.value.length < arr.length)
        textArray.value.push(arr[textArray.value.length]);
    };

    const scrollToBottom = () => {
      terminal.value.scrollTop = window.innerHeight;
    };

    const textArray = ref([]);

    return {
      textArray,
      addLine,
      loadingLogs,
      getDate,
      introEnded,
      inProgress,
      terminal
    };
  }
};
</script>
<style lang="scss">
.manifesto {
  width: 100%;
  height: calc(100% - 40px);
  max-height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  padding-bottom: 60px;
  font: 14px 'JetBrains Mono', monospace !important;

  .writer {
    margin-bottom: 10px;
    font-weight: bold;
    .typed {
      color: #00ff22;
      text-shadow: 0 0 5px #18ff10;
    }
  }

  .welcome {
    margin: 20px 0px;
    color: white;
    font-size: 16px;
    text-shadow: 0 0 30px white;

    animation: rainbowText 3000ms infinite;

    .ascii {
      display: flex;
    }
  }

  .intro {
    margin-bottom: 10px;

    .typed {
      color: #ffa200;
      text-shadow: 0 0 5px #ffb210;
    }
  }
}

.text {
  color: white;
  text-shadow: 0 0 5px white;
  animation: 2s infinite blinking;
  background: transparent;
}

.red {
  color: red;
  text-shadow: 0 0 5px red;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
