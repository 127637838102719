<template>
  <div class="journal">
    <div class="left">
      <div class="buttons">
        <div
          class="button"
          :class="order === 'least_recent' ? 'active' : ''"
          @click="setOrder('least_recent')"
        >
          Least Recent
        </div>
        <div
          class="button"
          :class="order === 'most_recent' ? 'active' : ''"
          @click="setOrder('most_recent')"
        >
          Most Recent
        </div>
      </div>
      <div
        class="entry"
        :class="currentEntryIndex === index ? 'selected' : ''"
        v-for="(entry, index) in entries"
        :key="entry"
        @click="currentEntryIndex = index"
      >
        <div class="title">{{ entry.title }}</div>
        <div class="date">{{ entry.date }}</div>
      </div>
    </div>
    <div class="right">
      <div class="contentWrapper">
        <p class="date">{{ entries[currentEntryIndex].fullDate }}</p>
        <p class="title">{{ entries[currentEntryIndex].title }}</p>
        <p
          class="line"
          v-for="line in entries[currentEntryIndex].content"
          :key="line"
        >
          {{ line }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';

export default {
  components: {},

  setup() {
    const order = ref('least_recent');
    const entries = ref([
      {
        title: 'My Name Is Shad0e 👋',
        date: '9/7/2999',
        fullDate: 'September 7th, 2999 at 2:13 PM',
        content: [
          'My name is Shad0e & I guess I am an aspiring vintage designer / artist from the year 2999. I always found it weird needing to give myself a title— I never really felt at home in any one category 🚀',
          'I love streetwear, art, & music that originated before, during, and after the historic Y2K era 🤘💿🎨. I remember when my history teacher in high school taught us about the dawn of NFTs way back in 2021, and my mind was blown at how nobody was really doing much with what was potentially the most disruptive technological innovation.',
          'We had to do a project back then to mimic one of those 10k avatar cash grabs that people were doing 😹. Back then I didn’t really see a point in dedicating time to school when all I really wanted to do was draw.',
          'Art back then used to be so cool & people were able to create things without any restrictions... not exactly like it is in the time I got stuck in.',
          'Fast forward to now— I’m 22, broke, and am spending my Tuesday afternoon watching old tapes of the 2021 NBA Finals 🤦‍♂️. Last week one of my homies was talking about how he got his hands on a stolen cloning machine & how we should go mess around w/ it to see how it works lmao.'
        ]
      },
      {
        title: 'What the fuck...',
        date: '9/7/2999',
        fullDate: 'September 7th, 2999 at 12:11 AM',
        content: [
          'Holy shit… I really though I’d seen it all before today 🤯',
          'The cloning machine fucking worked, but it kinda wasn’t what I expected. After checking it out, I sparked up a j and realized the machine clones you but whatever is in your subconscious influences the way that the clones get created (bucks in six baby) 🦌',
          'Well once we got all the clones from the machine I thought it’d be dope to do a quick photo shoot of em from stuff I had in my closet 📸. Now I got about 10k pics on my SD card and don’t know what to do w em. 🤷‍♂️'
        ]
      },

      {
        title: 'New Beginnings.',
        date: '9/14/2999',
        fullDate: 'September 14th, 2999 at 5:32 PM',
        content: [
          'I started messing around with coding again since that’s technically what I was studying back before I got kicked outta school for my “defiant artistic endeavors”.. so dumb 🥱. Anyways, I spun up a splash page for the project. My prof was tryna tell me that "bootleg" is an unsuitable word to begin any respectable business venture. 💀',
          'Since my proj failed I thought I’d just try and put it up anyways. I figured out how to stream my computer interface so that any user with those NFTs would be able to log into my account. I guess I could try and connect those bootleg buck pics I took from the shoot. 🤔',
          'Maybe I can see if my buddy can actually retroactively deploy those smart contracts to an ethereum block back in time. 🙌'
        ]
      },
      {
        title: 'Time Travel?',
        date: '9/19/2999',
        fullDate: 'September 19th, 2999 at 3:03 AM',
        content: [
          '2021 is the sweet spot— I always said if I could go back in time I’d head straight to the early 2000’s 🔥 ',
          "I think what I’m gonna do is link these pics of the clones to the NFTs and send em back in time when NFTs were just starting to pop off. My goal w this is to finally get a community that fucks with the art I’ve been making 🙏. I always said that I’ve gotta put my stuff out there in order for my shit to ever have a chance to change the world. It's just never been possible for me out here cuz I'll get in trouble for straying too far from the norm 🚨",
          'Once these are deployed (and if they even get minted), I’m gonna create the dopest shit for the audience that supported my stuff before the name SHAD0E even any meaning 💥',
          "The art, music & culture that flows through our veins is gonna be so strong that we’re gonna change the way that history unfolded 💯. When I come back to 2999 my art isn’t gonna be considered “useless” anymore cuz we'll be able to ride this wave into the future 🌊",
          "Maybe I’m just crazy to think that this is possible but all I know is I've gotta try 🤞…"
        ]
      }
    ]);

    const setOrder = _order => {
      if (order.value !== _order) {
        order.value = _order;
        entries.value.reverse();
      }
    };

    const currentEntryIndex = ref(0);

    //

    const date = ref(new Date());
    const dayName = ref('');
    const monthName = ref('');
    const dayNumber = ref('');
    const getDate = () => {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      date.value = new Date(year + 978, month, day);

      let days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];

      dayName.value = days[date.value.getDay()];
      monthName.value = monthNames[date.value.getMonth()];
      dayNumber.value = date.value.getDay() + 1;
    };

    onMounted(() => {
      getDate();
    });

    // Calendar Events
    const currentDetailsIndex = ref(null);
    const eventClasses = ['first', 'second', 'third'];
    const toggleDetails = index => {
      currentDetailsIndex.value = index;
    };

    return {
      date,
      order,
      dayName,
      monthName,
      dayNumber,
      eventClasses,
      toggleDetails,
      currentDetailsIndex,
      entries,
      currentEntryIndex,
      setOrder
    };
  }
};
</script>
<style scoped lang="scss">
.journal {
  display: grid;
  grid-template-columns: 300px auto;
  height: 100%;
  .left {
    position: relative;
    overflow: scroll;
    background-color: rgba(37, 37, 37, 0.9);

    .buttons {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 10px;
      padding: 10px;

      .button {
        font-weight: 200;
        font-size: 12px;
        padding: 10px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        border-radius: 10px;
        transition: all ease-in 100ms;

        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }

        &:active {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }

      .active {
        color: rgb(221, 188, 0);
        font-weight: bold;
      }
    }

    .entry {
      padding: 20px;
      border-radius: 10px;
      margin: 10px;

      .title {
        margin-bottom: 10px;
      }

      .date {
        font-weight: normal;
      }
    }

    .selected {
      background-color: rgb(221, 188, 0);
      color: white;
    }
  }

  .right {
    overflow-y: scroll;
    background-color: rgba(37, 37, 37, 0.7);
    padding-bottom: 100px;
    .contentWrapper {
      padding: 20px;
      height: 100%;
      .date {
        font-weight: normal;
        font-style: italic;
        text-align: center;
        opacity: 0.5;
        margin-bottom: 40px;
      }
      .title {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      .line {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
