<template>
  <div class="header">
    <!-- <img src="../../../images/buck-mob-logo.svg" /> -->
    <div class="message">
      <p>
        WELCOME TO THE BOOTLEG BUCK MOB! CLICK BELOW TO ENTER AN INTERACTIVE WEB
        PORTAL TO THE YEAR 2999...
      </p>
      <p>
        WELCOME TO THE BOOTLEG BUCK MOB! CLICK BELOW TO ENTER AN INTERACTIVE WEB
        PORTAL TO THE YEAR 2999...
      </p>
      <!-- <p class="second">
        WELCOME TO THE BOOTLEG BUCK MOB! CLICK BELOW TO ENTER AN INTERACTIVE WEB
        PORTAL TO THE YEAR 2999.
      </p> -->
    </div>

    <!-- <img src="../../../images/buck-icon.svg" class="logo" /> -->
  </div>
  <!-- <div class="info">
    <p>Visit Desktop site for full interactive experience.</p>
  </div> -->
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.header {
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 3px solid;
  border-bottom: 3px solid;
  animation-duration: 7s;
  animation-name: colorChange;
  animation-iteration-count: infinite;
  background-color: #1c2020;
  overflow: hidden;
  img {
    margin: 10px;
    height: 80%;
    width: 100%;
    object-fit: contain;
  }

  .message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      font-family: PressStart;
      font-size: 18px;
      animation-duration: 7s;
      animation-name: rainbowText;
      animation-iteration-count: infinite;

      display: inline-block;
      white-space: nowrap;
      animation: floatText 30s infinite linear, rainbowText 7s infinite;
      // padding-left: 100%; /*Initial offset*/
    }

    .second {
      animation-delay: 2.5s;
    }
  }

  @keyframes floatText {
    to {
      transform: translateX(-100%);
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    margin-left: 30px;
    margin-right: 30px;
  }

  @keyframes colorChange {
    0% {
      border-color: #e81b56;
      box-shadow: 0 0 5px #e81b56;
    }

    16% {
      border-color: #ea760f;
      box-shadow: 0 0 5px #ea760f;
    }

    32% {
      border-color: #ffbf00;
      box-shadow: 0 0 5px #ffbf00;
    }

    48% {
      border-color: #21dc3a;
      box-shadow: 0 0 5px #21dc3a;
    }

    64% {
      border-color: #317fff;
      box-shadow: 0 0 5px #317fff;
    }

    80% {
      border-color: #8b32f4;
      box-shadow: 0 0 5px #8b32f4;
    }

    100% {
      border-color: #e81b56;
      box-shadow: 0 0 5px #e81b56;
    }
  }
}

.info {
  display: none;
  // background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  justify-content: center;
  p {
    color: yellow;
    font-style: italic;
  }
}

@media (max-width: 900px) {
  .info {
    display: flex;
  }

  .header {
    display: none;
  }
}
</style>
