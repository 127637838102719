'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  // startDate: new Date(1626897600 * 1000),
  // shopAddress: '0xC147f9FcCdfF86366F0B3d82579df6E4E86e4BbE',
  shop: null,
  bucks: [],
  ownsBuck: false,
  merkleUserIndex: '',
  merkleClaimedStatus: true,
  merkleProofs: [],
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
