<template>
  <div class="gallery">
    <div class="sidePane">
      <p class="title">Categories</p>
      <DropdownMenu
        class="selector"
        :items="categories[category]"
        v-for="(category, index) in Object.keys(categories)"
        :key="index"
        :selection="filters[category]"
        @selected="handleSelection(category, $event)"
      />
    </div>
    <div class="main">
      <div class="imageContainer">
        <div class="buck" v-for="index in 14" :key="index">
          <!-- <img :src="require(`@/images/sample-bucks/${index}.png`)" /> -->
          <p class="description">buck_{{ index }}.png</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import { ref } from 'vue';
export default {
  components: {
    DropdownMenu
  },

  setup() {
    const categories = {
      Background: ['8bit', 'Blue', 'Red', 'Yellow'],
      Fur: ['Brown'],
      Antlers: ['Ivory'],
      Headwear: ['Black Hat'],
      Eyes: ['Droopy'],
      Mouth: ['Drool'],
      Piercings: ['Gold Studs'],
      Clothing: ['Black Tee']
    };

    const filters = ref({
      Background: 'Background',
      Fur: 'Fur',
      Antlers: 'Antlers',
      Headwear: 'Headwear',
      Eyes: 'Eyes',
      Mouth: 'Mouth',
      Piercings: 'Piercings',
      Clothing: 'Clothing'
    });

    const handleSelection = (category, selection) => {
      filters.value[category] = selection;
    };

    return {
      categories,
      filters,
      handleSelection
    };
  }
};
</script>
<style scoped lang="scss">
.gallery {
  display: grid;
  grid-template-columns: 350px 6fr;
  height: 100%;
  .sidePane {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(100px);
    padding: 20px;
    max-height: 100%;
    overflow-y: scroll;
    padding-bottom: 100px;
    .selector {
      margin-bottom: 20px;
    }

    .title {
      font-size: 12px;
      font-weight: normal;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }

  .main {
    max-height: 100%;
    overflow-y: scroll;
    padding-top: 10px;
    .imageContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      padding: 10px;
      margin-bottom: 40px;
      width: 100%;
      .buck {
        border-radius: 10px;
        width: 100%;
        max-width: 320px;
        min-width: 200px;
        justify-self: center;
        img {
          border-radius: 10px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          width: 100%;
          margin-bottom: 10px;
        }

        .description {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .gallery {
    .main {
      .imageContainer {
        grid-template-columns: 1fr;
        .buck {
        }
      }
    }
  }
}
</style>
