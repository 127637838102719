<template>
  <div class="faqItem">
    <div class="row" @click="showAnswer = !showAnswer">
      <div class="title">
        <p class="carrot" :class="showAnswer ? 'flip' : ''">></p>
        <p class="carrot" :class="showAnswer ? 'flip' : ''">></p>
        <p class="titleText">{{ title }}</p>
      </div>
    </div>
  </div>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <div v-if="showAnswer" class="answer">
      <p v-for="(line, index) in answer" :key="index">
        {{ line }}
      </p>
    </div>
  </transition>
</template>
<script>
import { ref } from 'vue';
export default {
  props: {
    title: String,
    answer: Array
  },

  methods: {
    enter(el) {
      el.style.height = 'auto';
      const height = getComputedStyle(el).height;
      el.style.height = 0;

      getComputedStyle(el).height;

      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    leave(el) {
      el.style.height = getComputedStyle(el).height;
      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    }
  },

  setup(props) {
    const showAnswer = ref(false);

    return {
      showAnswer
    };
  }
};
</script>
<style scoped lang="scss">
.faqItem {
  .row {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    transition: all 100ms ease-in;
    cursor: pointer;
    margin: 10px 0px;
    border: 2px solid white;
    box-shadow: 0px 0px 10px white;
    font-size: 20px;
    text-transform: uppercase;
    font-style: italic;
    color: white;
    text-shadow: 0 0 5px white;

    &:hover {
      animation: rainbowText infinite 5000ms, rainbowBorder infinite 3000ms;
    }
    .circleOutline {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }

    .selected {
      border: 2px solid rgb(46, 99, 255);
    }
    .circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgb(46, 99, 255);
    }

    .title {
      font-size: 18px;
      display: flex;
      align-items: center;

      .titleText {
        margin-left: 10px;
      }

      .carrot {
        font-style: normal;
        transition: all 100ms ease-in;
      }

      .flip {
        transform: rotateZ(90deg);
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.answer {
  color: rgb(0, 255, 13);
  text-shadow: 0 0 5px rgb(0, 255, 13);
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  // margin: 20px 0px;
  margin-left: 20px;
  margin-right: 20px;

  p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.7em;
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: all 200ms ease;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
}
</style>
