<template>
  <div class="footer">
    <div class="dockWrapper">
      <DockIcon
        class="dockIcon"
        :label="item.label"
        :image="item.image"
        :url="item.url"
        v-for="(item, index) in apps"
        @click="openWindow(item)"
        :key="index"
      />
      <div class="line"></div>
      <DockIcon
        class="dockIcon"
        :label="item.label"
        :image="item.image"
        :url="item.url"
        v-for="(item, index) in links"
        :key="index"
      />
    </div>
  </div>
</template>
<script>
import DockIcon from '/src/pages/home/components/DockIcon.vue';
import { useStore } from 'vuex';
export default {
  components: {
    DockIcon
  },
  setup() {
    const store = useStore();
    const openWindow = item => {
      if (item.url === '') {
        store.dispatch('windows/open', { label: item.label });
      }
    };

    const links = [
      {
        label: 'Discord',
        image: 'discord-logo',
        url: 'https://discord.gg/UfdenFzyjd'
      },
      {
        label: 'Twitter',
        image: 'twitter-logo',
        url: 'https://twitter.com/bootlegbuckmob'
      },
      {
        label: 'Opensea',
        image: 'opensea-logo',
        url: 'https://opensea.io/'
      }
    ];

    const apps = [
      {
        label: 'Calendar',
        image: 'calendar-icon',
        url: ''
      },
      {
        label: 'Provenance',
        image: 'excel-icon',
        url: ''
      },
      {
        label: 'FAQ',
        image: 'reminders-icon',
        url: ''
      },
      {
        label: 'Journal',
        image: 'notes-icon',
        url: ''
      }
    ];
    return {
      links,
      apps,
      openWindow
    };
  }
};
</script>
<style scoped lang="scss">
.footer {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  height: 70px;
  width: 100%;

  .dockWrapper {
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0.15);
    width: fit-content;
    height: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 5px 10px;

    .dockIcon {
      margin: 10px 5px 5px 5px;
    }

    .line {
      height: 55px;
      margin: 0px 10px;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
