<template>
  <div class="collection">
    <div class="headerWrapper">
      <animated-component animationType="left">
        <h1 class="header">The Clones.</h1>
      </animated-component>
      <!-- <button>Show More</button> -->
    </div>

    <div class="imageContainer">
      <animated-component animationType="left">
        <div class="imageWrapper">
          <img src="../../../images/sample-bucks/collection/0.png" />
        </div>
      </animated-component>
      <animated-component>
        <div class="imageWrapper">
          <img src="../../../images/sample-bucks/collection/8.png" />
        </div>
      </animated-component>
      <animated-component animationType="right">
        <div class="imageWrapper">
          <img src="../../../images/sample-bucks/collection/6.png" />
        </div>
      </animated-component>
      <animated-component animationType="left">
        <div class="imageWrapper">
          <img src="../../../images/sample-bucks/collection/9.png" />
        </div>
      </animated-component>
      <animated-component>
        <div class="imageWrapper">
          <img src="../../../images/sample-bucks/collection/1.png" />
        </div>
      </animated-component>
      <animated-component animationType="right">
        <div class="imageWrapper">
          <img src="../../../images/sample-bucks/collection/3.png" />
        </div>
      </animated-component>
    </div>

    <!-- <button class="mobileButton">Show More</button> -->
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
button {
  height: 100%;

  font-size: 18px;
  font-family: PressStart;
  animation-duration: 10s;
  animation-name: colorChange1;
  animation-iteration-count: infinite;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid white;
  transition: all 200ms ease-in;
  cursor: pointer;
  color: white;
  animation-name: rainbowText, rainbowBorder;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  background-color: transparent;

  &:hover {
    animation-name: rainbowBackground;
  }

  &:active {
    animation-name: none;
    background-color: rgb(255, 0, 191);
  }
}

.collection {
  margin: auto;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mobileButton {
    display: none;
    margin-top: 40px;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .header {
      font-family: CooperBlackOutline;
      font-size: 50px;
      font-weight: lighter;
      color: #ffbf00;
      text-shadow: 0 0 5px #ffbf00;

      margin-bottom: 20px;
    }
  }

  .imageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 20px;
    gap: 20px;
    .imageWrapper {
      flex: 1;
      img {
        width: 100%;
        border: 3px solid white;
        box-shadow: 0 0 10px white;
        animation-name: rainbowBorder;
        animation-duration: 6s;
        animation-iteration-count: infinite;
        border-radius: 10px;
        border: 3px solid white;
        transition: transform 120ms ease-in;

        &:hover {
          transform: translate(-10px, -10px);
          animation-name: none;
          box-shadow: 0px 0px 20px white;
        }

        &:active {
          transform: translate(0px, 0px);
          border: 3px solid rgb(255, 0, 170);
          box-shadow: 0px 0px 20px rgb(255, 0, 170);
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .headerWrapper {
    flex-direction: column;
  }
}

@media (max-width: 799px) {
  .collection .headerWrapper button {
    display: none;
  }

  .collection .headerWrapper {
    justify-content: center;
  }

  .collection .mobileButton {
    display: initial;
  }
}

@media (max-width: 750px) {
  .collection .imageContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 750px) {
  .collection .imageContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 570px) {
  .collection .imageContainer {
    grid-template-columns: 1fr;
  }
}
</style>
