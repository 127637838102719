<template>
  <div class="scavengerHunt">
    <p class="title">
      Welcome to the Bootleg Buck Mob Scavenger Hunt.
    </p>

    <Countdown
      class="countdown"
      :startDate="startDate"
      :endDate="new Date(2023, 1, 1)"
    />
    <p class="subtext">
      While the clock is ticking, the form below will be open for submission.
    </p>

    <div class="imageWrapper">
      <img :src="require(`@/images/scavenger-hunt/1.png`)" />
    </div>
    <p class="subtext">Which album cover is SHAD0E referencing?</p>
    <button
      class="rainbowButton"
      @click="navigateTo('https://forms.gle/6sTosPepEHbXks3m7')"
    >
      SUBMIT ANSWER
    </button>
  </div>
</template>
<script>
import Countdown from '/src/components/ui/Countdown.vue';
export default {
  components: {
    Countdown
  },

  setup() {
    const navigateTo = url => {
      window.open(url, '_blank');
    };

    const startDate = new Date(2021, 9, 19, 21);

    return {
      navigateTo,
      startDate
    };
  }
};
</script>
<style scoped lang="scss">
.scavengerHunt {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 30px;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 70px;

  .title {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 20px;
  }

  .subtext {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  .countdown {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .imageWrapper {
    height: 300px;
    width: 300px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;

      border-radius: 10px;
      border: white 3px solid;
      animation: rainbowBorder infinite 5s;
    }
  }

  button {
    margin: 0;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
  }
}
</style>
