<template>
  <div class="desktopIconWrapper">
    <AnimatedArrowIcon v-if="showArrow" />
    <div v-if="isDisabled && showTooltip" class="tooltip">{{ tooltip }}</div>
    <div
      class="desktopIcon"
      :class="isDisabled ? 'disabled' : ''"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <img :src="require(`@/images/${icon}.png`)" />
      <p>{{ label }}</p>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import AnimatedArrowIcon from '/src/components/icons/AnimatedArrowIcon.vue';
export default {
  components: {
    AnimatedArrowIcon
  },

  props: {
    label: String,
    icon: String,
    isDisabled: Boolean,
    tooltip: String,
    showArrow: Boolean
  },

  setup(props) {
    const showTooltip = ref(false);
    return { showTooltip };
  }
};
</script>
<style scoped lang="scss">
.desktopIconWrapper {
  position: relative;

  .tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -200%);
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(100px);
    padding: 10px;
    text-align: center;
  }
  .desktopIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    transition: 100ms ease-in all;

    // No select
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 10px;
    }

    img {
      height: 30px;
      width: 30px;
      object-fit: contain;
      margin-bottom: 15px;
    }
  }

  .disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
  }
}
</style>
