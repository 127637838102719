<template>
  <div class="roadmap">
    <animated-component animationType="left">
      <h1 class="header">Roadmap</h1>
    </animated-component>

    <div class="roadmapWrapper">
      <animated-component>
        <div class="dropWrapper">
          <p class="title">post mint</p>
          <RoadmapItem
            class="item"
            v-for="item in items"
            :title="item.title"
            :answer="item.answer"
            :key="item"
          />
          <!-- <img class="cursor" src="../../../images/cursor.gif" /> -->
        </div>
      </animated-component>

      <animated-component animationType="left">
        <div class="futureWrapper">
          <p class="title">Q1 2022</p>

          <RoadmapItem
            class="item"
            v-for="item in firstquarter"
            :title="item.title"
            :answer="item.answer"
            :key="item"
          />

          <p class="title">Q2 2022 - the future</p>
          <RoadmapItem
            class="item"
            v-for="item in future"
            :title="item.title"
            :answer="item.answer"
            :key="item"
          />
          <!-- <img class="cursorFlipped" src="../../../images/cursor.gif" /> -->
        </div>
      </animated-component>
    </div>
    <animated-component>
      <p class="subtitle">
        This roadmap outlines the direction the mob will move in. There are a
        lot of ideas and concepts that are being explored, and it may evolve
        over time and will be a collaborative process with the community.
      </p>
    </animated-component>
  </div>
</template>
<script>
import RoadmapItem from './RoadmapItem.vue';
export default {
  components: {
    RoadmapItem
  },

  setup() {
    const items = [
      // {
      //   title: ' Minting goes Live',
      //   answer: [
      //     'Bootleg Buck Mob NFTs will be available to mint in the portal'
      //   ]
      // },
      {
        title: ' AIRDROP BUcks to our holders',
        answer: [
          'We will select 10 random holders of buck mob nfts and will airdrop each person 1 buck. This will be a random announcement, happening within 3 weeks of the drop.'
        ]
      },
      {
        title: ' mobtape volume 1 release',
        answer: [
          "We will be creating a special playlist for the mob, living in the portal. The strongest communities are tied together by more than just holding the same jpegs. Future playlists will be created in collaboration with the mob & we'll all be jammin' out together in the metaverse."
        ]
      },
      {
        title: ' Personalized Artwork WALLPAPERS',
        answer: [
          'A new directory will appear in the portal and will only be available to the mob. In this directory, there will be personalized artwork for phone + desktop wallpapers determined by the NFTs in your wallet. We look forward to the mob sharing and trading different wallpapers in the community.'
        ]
      },
      {
        title: ' mystery 3d Print unlock',
        answer: [
          'A new directory will appear with files including a 3d print file. The artwork will be revealed then, and only the mob will be able to view + download the file to print the sculpture that awaits...'
        ]
      },
      {
        title: ' 1-1 bucks airdrop',
        answer: [
          'After we are sold out, we will be airdropping 9 unique hand-drawn 1-1 bucks to our holders. we will have contests to reward active members of the mob with these gems.'
        ]
      }
      // {
      //   title: ' Streetwear development begins',
      //   answer: [
      //     'While completing all of the above items, we will be setting up our streetwear drop. The team is very experienced in streetwear development and aims to deliver the highest quality clothing + sickest designs the NFT space has ever seen. We will be dropping exclusive previews + updates in the portal along the way.'
      //   ]
      // }
      // {
      //   title: ' ongoing updates to the portal',
      //   answer: [
      //     'Consider the current portal a trailer to the journey we have planned ahead. As we grow with our community, we will be working together to help shad0e customize this experience from 2999...'
      //   ]
      // }
    ];

    const firstquarter = [
      {
        title: ' The Bootleg Streetwear Drop',
        answer: [
          'We will be delivering a carefully designed, ethically sourced collection of clothing from the year 2999. we aim to set a new standard for streetwear in the NFT space, and the mob will be the only community able to experience it.'
        ]
      },
      {
        title: " SHADOE's art exhibit",
        answer: [
          "Shadoe will be traveling back in time to 2022 in order to put on a physical art show. Here, shadoe will showcase some designs from the future & this will be our community's first meetup."
        ]
      }
    ];

    const future = [
      {
        title: ' chapter 2 roadmap unlock',
        answer: [
          'This is just the beginning... the second stage of our journey will be focused on expanding our universe & connecting the dots from now to 2999.',
          'Remember that friend shad0e mentioned in his journal? Rumor has it that he left the password to his computer somewhere in the portal ;)'
        ]
      }
      // {
      //   title: ' Community Dao ; a journey to the year 2999...',
      //   answer: [
      //     "after building a strong community, we will empower the mob to pave the path to 2999. shadoe's clones will be have voting power to determine the way the mob moves."
      //   ]
      // }
    ];

    // Rarity Tools / Sniper

    // can we do milwaukee bucks game

    // const firstquarter = [
    //   {
    //     title: ' The Bootleg Streetwear Drop',
    //     answer: [
    //       'We will be delivering a carefully designed, ethically sourced collection of clothing from the year 2999. we aim to set a new standard for streetwear in the NFT space, and the mob will be the only community able to experience it.'
    //     ]
    //   },
    //   {
    //     title: " SHADOE's art exhibit",
    //     answer: [
    //       "Up until now, we have created a universe inside of shad0e's computer. The next step in this series of interactive web experiences is for us to create an entirely new portal, continuing the storyline in 2999. this will be our unique take on a derivative project, and the mob will be the first to experience the beginning of our next chapter.",
    //       'Remember that friend shad0e mentioned in his journal? Rumor has it that he left his password somewhere in the portal ;)'
    //     ]
    //   }
    // ];

    return {
      items,
      firstquarter,
      future
    };
  }
};
</script>
<style scoped lang="scss">
.roadmap {
  margin: auto;
  max-width: 1100px;
  .header {
    font-family: CooperBlackOutline;
    font-size: 50px;
    font-weight: lighter;
    color: rgb(0, 255, 13);
    text-shadow: 0 0 5px rgb(0, 255, 13);

    margin-bottom: 20px;
  }
  .subtitle {
    font-size: 16px;
    text-transform: uppercase;
    font-style: italic;
    color: rgb(0, 255, 13);
    text-shadow: 0 0 5px rgb(0, 255, 13);
    margin: 20px 10px 20px 0px;
    text-align: center;
    line-height: 1.4em;
  }

  p {
    font-size: 16px;
    text-transform: uppercase;
    font-style: italic;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 5px rgb(255, 255, 255);
    margin: 20px 10px 20px 0px;
  }

  .roadmapWrapper {
    gap: 20px;

    .dropWrapper {
      width: 100%;
      border: 2px solid white;
      box-shadow: 0px 0px 10px white;
      transition: border 100ms ease;
      animation: rainbowBorder infinite 5000ms;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      gap: 5px;
      position: relative;

      &:hover {
        animation: none;
      }

      .cursor {
        position: absolute;
        bottom: -20px;
        right: -20px;
        width: 40px;
        height: 40px;
      }

      p {
        margin-bottom: 10px;
      }

      .item {
        margin: 20px;
      }

      .title {
        font-size: 24px;
      }
    }

    .futureWrapper {
      width: 100%;
      border: 2px solid white;
      box-shadow: 0px 0px 10px white;
      animation: rainbowBorder infinite 5000ms;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      gap: 5px;
      position: relative;

      &:hover {
        animation: none;
      }

      .cursorFlipped {
        position: absolute;
        bottom: -20px;
        left: -20px;
        transform: rotateY(180deg) rotateZ(-10deg);

        width: 40px;
        height: 40px;
      }

      .cursor {
        position: absolute;
        bottom: -25px;
        right: -20px;
        width: 40px;
        height: 40px;
      }

      p {
        margin-bottom: 10px;
      }

      .title {
        font-size: 24px;
      }
    }
  }
}
</style>
