<template>
  <div class="dockIcon">
    <div v-if="showTooltip" class="tooltip">{{ label }}</div>
    <img
      :src="require(`@/images/${image}.png`)"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
      @click="openUrl()"
    />
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
  props: {
    label: String,
    image: String,
    url: String
  },

  setup(props) {
    const showTooltip = ref(false);
    const openUrl = () => {
      if (props.url !== '') {
        window.open(props.url, '_blank');
      }
    };

    return {
      showTooltip,
      openUrl
    };
  }
};
</script>
<style scoped lang="scss">
.dockIcon {
  position: relative;
  // No select
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  .tooltip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -270%);
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(100px);
    padding: 5px 10px;

    &::after {
      /* Tooltip arrow */

      content: '';
      position: absolute;
      top: 105%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.3) transparent transparent transparent;
    }
  }
  img {
    height: 45px;
    width: 45px;
    object-fit: contain;
    cursor: pointer;
    transition: 100ms ease-in all;

    &:active {
      opacity: 0.7;
      transform: scale(0.97);
    }
  }
}
</style>
