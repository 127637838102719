<template>
  <div class="member">
    <p>
      <span>WUS BUCKIN'</span> {{ userName() }}, and a very warm welcome to the
      mob 🦌
    </p>
    <p>
      You are now officially part of an exclusive community with unique ties to
      the year 2999.
    </p>
    <p>
      The <span>admin credentials to my computer have been granted</span>, and
      from now til 2999, your NFTs will unlock mysterious directories containing
      surprises unavailable to everyone besides fellow members of the mob.
    </p>
    <p>
      With your help, we will be able to
      <span>grow this community into something your world's never seen</span>...
      til then, join us in our discord (it's where the party's happening)...
    </p>
    <p class="end">-- SHAD0E + his homies from the future.</p>
  </div>
</template>
<script>
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();

    const userName = () => {
      return store.state.ethers.address === undefined
        ? 'Guest'
        : store.state.ethers.address;
    };

    return { store, userName };
  }
};
</script>
<style scoped lang="scss">
.member {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
  font-style: italic;
  span {
    animation: rainbowText infinite 5000ms;
  }

  .end {
    animation: rainbowText infinite 5000ms;
    margin-top: 30px;
    margin-right: 20px;
    align-self: flex-end;
  }
}
</style>
