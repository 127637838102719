<template lang="">
  <div class="ring-container">
    <div class="circle"></div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="css">
.ring-container {
  position: relative;
  height: 100%;
  width: 10px;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: #3eed3e;
  border-radius: 50%;
  position: absolute;
  top: 42%;
  left: 50%;
  -webkit-animation: pulsate 2.5s ease-out;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.7, 0.7);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
