<template>
  <div class="connectingAnimation">
    <div class="connecting">
      <p class="text">{{ messages[index] }}</p>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
export default {
  setup() {
    const messages = [
      'Attempting Connection',
      'Establishing...',
      'Connection Succesful.'
    ];

    const index = ref(0);
    onMounted(() => {
      setTimeout(() => {
        index.value = index.value + 1;
      }, 1000);
      setTimeout(() => {
        index.value = index.value + 1;
      }, 2400);
    });

    return {
      index,
      messages
    };
  }
};
</script>
<style scoped lang="scss">
.connectingAnimation {
  height: 100vh;
  width: 100vw;
  background: url('../../../images/connecting.gif') no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  background-color: black;

  .connecting {
    font-size: 30px;
    margin-bottom: 170px;

    p {
      font-family: PressStart;
      animation-duration: 1800ms;
    }
  }
}
</style>
