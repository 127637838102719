<template>
  <Ethereum :callback="() => {}" />
  <transition name="fade" mode="out-in">
    <div
      class="wallet-modal-container"
      v-if="store.state.alert.showWalletModal"
    >
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <div class="navBar">
    <div class="mainnet" v-if="!isMainnet">
      Please Connect to Ethereum Mainnet
    </div>
    <div class="wrapper">
      <div class="leftMenu">
        <div id="menuIcon" class="item" @click="showDropdown">
          <img src="../../images/buck-logo.svg" />
        </div>
        <div class="dropdown" v-if="isDropdownVisible">
          <div class="item" @click="router.go(-1)">
            Log Out
          </div>
        </div>

        <div class="item">File</div>
        <div class="item">Edit</div>
        <div class="item">View</div>
      </div>
      <div class="rightMenu">
        <div class="icons">
          <img src="../../images/shield-icon.png" />
          <img src="../../images/battery-icon.png" />
          <img src="../../images/wifi-icon.png" />
        </div>
        <div class="date">
          <p>
            {{ date }}
          </p>
          <p>{{ time }}</p>
        </div>
        <PulseDot class="dot" v-if="store.state.ethers.address" />
        <div v-if="store.state.ethers.address" class="addressLabel">
          <div>{{ profileAddressLabel }}</div>
        </div>
        <div class="addressLabel" v-if="!store.state.ethers.address">
          <p class="guest">GUEST</p>
          <button class="login" @click="showWalletModalDisplay">
            Login
          </button>
        </div>
      </div>
    </div>

    <!-- The user is not connected to a signing provider. -->
  </div>
</template>

<script>
('use strict');

// Imports.
import { useStore } from 'vuex';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { formatAddress } from '/src/utility/format';
// Component imports.
import WalletModal from '../layout/WalletModal.vue';
import PulseDot from '/src/components/ui/PulseDot.vue';

// Icon imports.
import Ethereum from '/src/components/common/Ethereum.vue';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    WalletModal,
    Ethereum,
    PulseDot
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const mobileMenuIsVisible = ref(false);
    const time = ref('');
    const date = ref('');
    const isDropdownVisible = ref(false);

    onMounted(() => {
      getDate();
      setInterval(() => {
        getDate();
      }, 1000);

      document.addEventListener('click', hideDropdown);
    });

    onUnmounted(() => {
      document.removeEventListener('click', hideDropdown);
    });

    //     close(e) {
    //     if (!this.$el.contains(e.target)) {
    //       this.showDropdown = false;
    //     }
    //   }
    // },

    // mounted() {
    //   document.addEventListener('click', this.close);
    // },

    // onBeforeUnmount() {
    //   document.removeEventListener('click', this.close);
    // }

    const profileAddressLabel = computed(() => {
      return formatAddress(store.state.ethers.address);
    });

    const getDate = () => {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      date.value = new Date(year + 978, month, day).toDateString();
      time.value = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    };

    // Methods to hide/show wallet modal.
    const showWalletModalDisplay = () => {
      console.log('aksfjklsdfjskldfj');
      store.dispatch('alert/showWalletModal');
    };
    const hideWalletModalDisplay = () => {
      store.dispatch('alert/hideWalletModal');
    };

    // Methods to connect/disconnect wallet.
    const connect = () => {
      store.dispatch('ethers/connectWallet');
    };
    const disconnect = () => {
      store.dispatch('ethers/disconnectWallet');
    };

    const routeTo = path => {
      router.push(path);
    };

    const openUrl = url => {
      window.open(url, '_blank');
    };

    const showMobileMenu = () => {
      // Disable scrolling on window.
      window.onscroll = function() {
        window.scrollTo(0, 0);
      };
      mobileMenuIsVisible.value = true;
    };

    const showDropdown = () => {
      isDropdownVisible.value = true;
    };

    const hideDropdown = e => {
      if (!document.getElementById('menuIcon').contains(e.target)) {
        isDropdownVisible.value = false;
      }
    };

    const isMainnet = computed(() => {
      return store.state.ethers.networkId === '0x1';
    });

    return {
      store,
      profileAddressLabel,
      mobileMenuIsVisible,
      showWalletModalDisplay,
      hideWalletModalDisplay,
      connect,
      disconnect,
      date,
      time,
      routeTo,
      openUrl,
      showMobileMenu,
      isDropdownVisible,
      showDropdown,
      hideDropdown,
      router,
      isMainnet
    };
  }
};
</script>

<style scoped lang="scss">
.navBar {
  background-color: black;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.05);
  height: fit-content;
  max-width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(100px);

  .mainnet {
    background-color: rgb(255, 238, 0);
    padding: 5px;
    text-align: center;
    color: black;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    // No select
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    .leftMenu {
      display: flex;
      align-items: center;
      margin-left: 5px;
      .item {
        padding: 5px 10px;
        cursor: pointer;
        height: 90%;
        width: 90%;
        display: flex;
        align-items: center;

        &:active {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        }
      }

      img {
        height: 20px;
        width: 20px;
      }

      .dropdown {
        position: absolute;
        border-radius: 10px;
        padding: 10px;
        top: 35px;
        width: 150px;

        background-color: rgba(0, 0, 0, 0.3);

        .item {
          padding: 5px 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 0px;
          height: 100%;
          width: 100%;

          &:hover {
            background-color: rgba(0, 102, 255, 0.74);
            border-radius: 5px;
          }

          &:active {
            background-color: rgb(0, 102, 255);
          }
        }
      }
    }

    .rightMenu {
      display: flex;
      align-items: center;
      .addressLabel {
        height: 100%;
        padding: 0px 20px 0px 30px;
        backdrop-filter: blur(100px);
        display: flex;
        align-items: center;

        .guest {
          color: red;
          font-weight: bold;
          margin-right: 10px;
        }

        .login {
          cursor: pointer;
          border: 1px solid white;
          color: white;
          padding: 5px 20px;
          background-color: transparent;
        }
      }

      .dot {
        margin-right: -20px;
      }

      .date {
        font-weight: normal;
        opacity: 0.8;
        padding-right: 20px;
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 5px;
      }

      .icons {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 20px;
        align-items: center;
        margin-right: 20px;

        img {
          height: 20px;
          width: 20px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
