'use strict';

// Specified state transitions per particular actions.
export default {
  open(state, { label }) {
    // Temporary fix bc z index is a little fucked.
    state.openWindows = new Set();
    state.openWindows.add(label);
    state.currentWindow = label;
  },

  close(state, { label }) {
    state.openWindows = new Set();
    state.currentWindow = 'none';
  }
};
