<template>
  <div class="calendarDetails">
    <div class="title">{{ data.title }}</div>
    <div class="location"><span>Location:</span> 📍{{ data.location }}</div>
    <div class="invites"><span>Guest List:</span> {{ data.guestList }}</div>
    <div class="description">
      <p v-for="item in data.description" :key="item">{{ item }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  }
};
</script>
<style scoped lang="scss">
.calendarDetails {
  padding: 20px;

  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .location {
    font-size: 18px;
    font-weight: lighter;
    font-style: italic;
    margin-bottom: 15px;

    span {
      font-weight: bold;
    }
  }

  .percentage {
    font-size: 14px;
    font-weight: normal;
    opacity: 0.9;
    margin-bottom: 15px;
    span {
      font-weight: bold;
    }
  }

  .invites {
    margin-bottom: 10px;
  }

  .description {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    font-size: 16px;
    font-weight: 100;
    p {
      margin-bottom: 20px;
    }
  }
}
</style>
