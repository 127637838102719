<template>
  <div class="clone">
    <img :src="clone.metadata.image" />
    <p>{{ name }}.jpeg</p>
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    clone: Object
  },

  setup(props) {
    const name = computed(() => {
      return props.clone.metadata.name
        .split(' ')
        .join('_')
        .replace('#', '');
    });
    return { name };
  }
};
</script>
<style scoped lang="scss">
.clone {
  padding: 20px;
  border-radius: 10px;
  transition: all 100ms ease-in;
  cursor: pointer;
  flex: 1;

  min-width: 250px;
  max-width: 300px;
  img {
    // border-radius: 10px;
    width: 100%;
    border: 5px solid white;
    box-shadow: 0 0 10px white;
    transition: all 1s ease-in;

    &:hover {
      animation-name: none;
      box-shadow: 0px 0px 20px white;
    }
  }

  img {
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    text-transform: lowercase;
    font-size: 12px;
    word-break: break-all;
    width: 100%;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid white;
  }
}
</style>
