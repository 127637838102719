<template>
  <div class="icon">
    <div class="arrow"></div>
  </div>
</template>

<style scoped lang="scss">
.icon {
  position: absolute;
  top: 0%;
  left: -50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 60px;
  cursor: pointer;
  transform: scale(0.35);
  animation: slide infinite 1000ms;
}

.arrow {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: #fff;
  animation: rainbowBackground infinite 1000ms;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.arrow::after,
.arrow::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  animation: rainbowBackground infinite 1000ms;
  background-color: #fff;
}

.arrow::after {
  top: -12px;
  transform: rotate(45deg);
}

.arrow::before {
  top: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  transform: rotate(-45deg);
}

@keyframes slide {
  0% {
    transform: translateX(-10px) scale(0.35);
  }

  50% {
    transform: translateX(0px) scale(0.35);
  }

  100% {
    transform: translateX(-10px) scale(0.35);
  }
}
</style>
