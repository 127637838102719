// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../pages/home/Home.vue';
import LandingPage from '../pages/landing/LandingPage.vue';
import TermsPage from '../pages/terms/TermsPage.vue';
import NotFound from '../pages/not-found/NotFound.vue';
// Create routes.
const routes = [
  {
    path: '/portal',
    name: 'Home',
    component: Home
  },

  {
    path: '/',
    name: 'Landing',
    component: LandingPage
  },

  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage
  },

  { path: '/:pathMatch(.*)*', component: NotFound },
  { path: '/portal', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
