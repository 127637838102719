'use strict';

// Specified actions for mutating the alert state.
export default {
  // Open a window.
  open({ commit }, { label }) {
    commit('open', { label });
  },

  // Close a window.
  close({ commit }, { label }) {
    commit('close', { label });
  }
};
