<template>
  <div class="welcomeWrapper">
    <p>
      <span>Greetings</span> ✌️ You have successfully established a connection
      to a computer from <span>the year 2999</span>.
    </p>
    <p>This device belongs to an artist named <span>SHAD0E</span> ☄️</p>
    <p>
      This interactive experience is his <span>latest art project</span> and
      will serve as an <span>exclusive portal</span> for his
      <span>community</span> 🎨
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.welcomeWrapper {
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.7);
  font-size: 20px;
  text-transform: uppercase;
  font-style: italic;

  p {
    margin-bottom: 30px;

    span {
      animation: rainbowText 3s infinite;
    }
  }
}
</style>
