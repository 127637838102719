<template>
  <div class="artwork">
    <!-- <model-stl src=""></model-stl> -->
  </div>
</template>
<script>
export default {
  // components: { ModelStl }
};
</script>
<style scoped lang="scss"></style>
