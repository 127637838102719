<template>
  <div class="tvMonitor">
    <img class="monitor" :src="require(`@/images/monitors/${color}.svg`)" />
    <div class="imageContainer">
      <!-- <video autoplay loop>
        <source :src="require(`@/images/videos/${5}.mp4`)" />
      </video> -->
      <img :src="require(`@/images/gifs/${index}.gif`)" />
      <!-- <div v-for="index in 10" :key="index">
        <img
          v-if="index == currentIndex"
          :src="require(`@/images/sample-bucks/${index}.png`)"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
export default {
  props: {
    color: String,
    index: Number
  },

  setup(props) {}
};
</script>
<style scoped lang="scss">
.tvMonitor {
  position: relative;
  max-height: 299px;
  max-width: 299px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .monitor {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .imageContainer {
    height: 79%;
    width: 79%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
