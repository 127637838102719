<template>
  <div class="linksWrapper">
    <animated-component>
      <button @click="backToTop">Back To Top</button>
    </animated-component>

    <div class="socialsWrapper">
      <animated-component animationType="left">
        <TwitterIcon
          class="icon"
          @click="navigateTo('https://twitter.com/bootlegbuckmob')"
        />
      </animated-component>
      <animated-component animationType="right">
        <DiscordIcon
          class="icon"
          @click="navigateTo('https://discord.gg/UfdenFzyjd')"
        />
      </animated-component>
    </div>
    <p
      class="contract"
      @click="
        navigateTo(
          'https://etherscan.io/address/0x32A834A7f2c2a1F2e74Bf7815d243Eb73374fB1D'
        )
      "
    >
      Smart Contract
    </p>
  </div>

  <div class="footer">
    <animated-component>
      <div class="ghostWrapper">
        <img
          class="ghost"
          :src="require(`@/images/sample-bucks/ghosts/ghost.png`)"
        />
      </div>
    </animated-component>
    <animated-component animationType="right">
      <div class="legalWrapper">
        <img src="../../../images/buck-mob-ghost-logo.svg" @click="backToTop" />
        <div class="terms lineTop">
          <p class="link" @click="routeTo('Terms')">[ Terms & Conditions ]</p>
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script>
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    TwitterIcon,
    DiscordIcon
  },

  setup() {
    const router = useRouter();
    const backToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const navigateTo = url => {
      window.open(url, '_blank');
    };

    const routeTo = name => {
      router.push({
        name: name
      });
    };

    return {
      backToTop,
      navigateTo,
      routeTo
    };
  }
};
</script>
<style scoped lang="scss">
.linksWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    font-size: 24px;
    font-family: PressStart;
    animation-duration: 10s;
    animation-name: rainbowText, rainbowBorder;
    animation-iteration-count: infinite;
    width: fit-content;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid white;
    transition: background-color 200ms ease-in;
    color: white;
    cursor: pointer;
    background-color: transparent;
    margin: auto;

    &:hover {
      animation-name: rainbowBackground;
    }

    &:active {
      animation-name: none;
      background-color: rgb(255, 0, 191);
    }
  }

  .socialsWrapper {
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 30px;
      width: 30px;
      margin: 20px;
      animation-name: none;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      transition: all 100ms ease-in;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        animation-name: rainbowText;
      }

      &:active {
        text-decoration: none;
        transform: scale(0.9);
      }
    }
  }

  .contract {
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    font-family: PressStart, 'Ubuntu', sans-serif;
    animation-name: none;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    transition: all 100ms ease-in;
    text-shadow: 0 0 5px white;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    &:hover {
      text-decoration: underline;
      animation-name: rainbowText;
    }

    &:active {
      text-decoration: none;
      transform: scale(0.95);
    }
  }
}

.footer {
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1100px;
  margin: auto;

  .legalWrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
    font-size: 18px;
    padding-bottom: 30px;

    width: fit-content;

    img {
      max-width: 300px;
      margin-bottom: -10px;
      margin-right: -15px;
      cursor: pointer;
      transition: all ease-in 100ms;

      &:active {
        transform: scale(0.95);
      }
    }
    .terms {
      display: flex;
      gap: 10px;
      justify-content: center;
    }
    p {
      padding-top: 15px;
      font-family: 'Ubuntu', sans-serif;
      text-align: center;
      text-transform: uppercase;
      font-style: italic;
      color: #83f2fd;
      text-shadow: 0 0 15px #83f2fd;
      // animation: rainbowText 5s infinite;
      font-size: 16px;
    }

    .link {
      &:hover {
        cursor: pointer;
        animation: rainbowText 3s infinite;
      }

      &:active {
        color: rgb(255, 0, 191);
        animation: none;
      }
    }

    .lineTop {
      border-top: 2px solid #83f2fd;
    }
  }

  .ghostWrapper {
    display: flex;
    justify-content: center;
    .ghost {
      width: 100%;
      height: 100%;
      max-width: 350px;
    }
  }
}

@media (max-width: 660px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ghostWrapper {
      margin-bottom: -30px;
    }
    .legalWrapper {
      align-items: center;
    }
  }
}
</style>
