<template>
  <div class="mainMonitor">
    <img class="monitor" :src="require(`@/images/monitors/tan.svg`)" />

    <div class="imageContainer">
      <div
        class="click"
        @click="enter"
        @mouseenter="isHovered(true)"
        @mouseleave="isHovered(false)"
      ></div>
      <img src="../../../images/bbm.gif" :class="hovered ? 'hovered' : ''" />
      <p
        class="text text-large"
        :class="hovered ? 'hoveredText' : ''"
        @click="enter"
      >
        [ CLICK HERE TO ENTER ]
      </p>
      <div class="countdownWrapper">
        <!-- <p class="text text-large">PORTAL OPENING SOON ;)</p> -->
        <!-- <Countdown
          :startDate="new Date(2021, 9, 6)"
          :endDate="new Date(2023, 1, 1)"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import Countdown from '/src/components/ui/Countdown.vue';
import { ref, computed } from 'vue';
export default {
  components: {
    // Countdown
  },

  emits: ['enter'],

  setup(props, context) {
    const textArray = ref([['Click To Enter']]);
    const hovered = ref(false);

    const isHovered = bool => {
      hovered.value = bool;
    };

    const enter = () => {
      console.log('hereh');
      context.emit('enter');
    };

    return {
      textArray,
      enter,
      isHovered,
      hovered
    };
  }
};
</script>
<style scoped lang="scss">
.mainMonitor {
  position: relative;
  max-height: 900px;
  max-width: 900px;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  .monitor {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 2;
  }

  .imageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in 100ms;
    overflow: hidden;

    .click {
      cursor: pointer;
      height: 70%;
      width: 70%;
      position: absolute;
      z-index: 10;
    }

    .text {
      position: absolute;
    }

    .hovered {
      transition: all ease-in 200ms;
      filter: contrast(300%) hue-rotate(90deg) brightness(2) invert(100%);
      transform: scale3d(1, 0.77, 10) translateY(-15px);
    }

    .countdownWrapper {
      flex-direction: column;
      margin-top: 25%;
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .countdown {
        margin-top: 15px;
      }
    }

    .text-large {
      margin-top: 25%;
      font-size: 16px;
      color: lime;
      text-shadow: 0 0 5px lime;
      font-family: PressStart;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      transition: all 150ms ease-in;

      // TODO: uncomment this

      &:active {
        background-color: lime;
        color: white;
        text-shadow: 0 0 5px white;
        animation: none;
      }
    }

    .hoveredText {
      // background-color: lime;
      border: 3px solid rgb(255, 0, 200);
      box-shadow: 0 0 10px rgb(255, 0, 200);
      color: rgb(255, 0, 200);
      text-shadow: 0 0 10px rgb(255, 0, 200);

      padding: 15px;
      border-radius: 5px;
      // color: magenta;
      // text-shadow: 0 0 5px magenta;
      animation: none;
      transform: translateY(-30px);
    }
    img {
      width: 80%;
      height: 80%;
      object-fit: contain;

      z-index: 0;

      &:hover {
        filter: contrast(100) brightness(0.4);
      }
    }
  }
}

@media (max-width: 1000px) {
  .mainMonitor .imageContainer .text-large {
    font-size: 14px;
  }
}
</style>
