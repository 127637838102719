<template>
  <div class="container">
    <transition name="slideUp">
      <div v-if="isVisible" class="loadingAnimation">
        <p v-if="initializing">Initializing</p>
        <transition name="stretch">
          <div v-if="!initializing" class="line"></div>
        </transition>
      </div>
    </transition>
    <transition name="slideDown">
      <div v-if="isVisible" class="loadingAnimation2">
        <transition name="stretch">
          <div v-if="!initializing" class="line"></div>
        </transition>

        <div v-if="initializing" class="loader">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
          <div class="bar4"></div>
          <div class="bar5"></div>
          <div class="bar6"></div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
export default {
  setup() {
    const isVisible = ref(true);
    const initializing = ref(true);

    onMounted(() => {
      setTimeout(() => {
        initializing.value = false;
      }, 2000);
    });

    onMounted(() => {
      setTimeout(() => {
        isVisible.value = false;
      }, 3000);
    });

    return {
      isVisible,
      initializing
    };
  }
};
</script>
<style scoped lang="scss">
.loadingAnimation2 {
  position: fixed;
  // background-color: black;
  // // height: 100%;
  width: 100%;
  z-index: 999;
  background-color: #1c2020;

  height: 50%;
  -webkit-transform: translateY(100%);
  // position: relative;
  overflow: hidden;

  .line {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-bottom: 3px solid white;
    animation-duration: 2s;
    animation-name: colorChange;
    animation-iteration-count: infinite;
  }

  .loader {
    margin: 0 auto;
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 10%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);

    > div {
      height: 100%;
      width: 8px;
      display: inline-block;
      float: left;
      margin-left: 2px;
      -webkit-animation: delay 0.8s infinite ease-in-out;
      animation: delay 0.8s infinite ease-in-out;
    }

    .bar1 {
      background-color: #e81b56;
      box-shadow: 0 0 5px #e81b56;
    }
    .bar2 {
      background-color: #ea760f;
      box-shadow: 0 0 5px #ea760f;
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
    .bar3 {
      box-shadow: 0 0 5px #ffbf00;
      background-color: #ffbf00;
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
    .bar4 {
      background-color: #21dc3a;
      box-shadow: 0 0 5px #21dc3a;
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    .bar5 {
      background-color: #317fff;
      box-shadow: 0 0 5px #317fff;
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    .bar6 {
      background-color: #8b32f4;
      box-shadow: 0 0 5px #8b32f4;

      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }

  @-webkit-keyframes delay {
    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.05);
    }
    20% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes delay {
    0%,
    40%,
    100% {
      transform: scaleY(0.05);
      -webkit-transform: scaleY(0.05);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
}
.loadingAnimation {
  position: fixed;
  // background-color: black;
  // // height: 100%;
  width: 100%;
  z-index: 999;
  background-color: #1c2020;
  height: 50%;
  // position: relative;
  overflow: hidden;

  .line {
    width: 100%;
    border-top: 3px solid white;
    animation-duration: 2s;
    animation-name: colorChange;
    animation-iteration-count: infinite;
    position: absolute;
    bottom: 0;
  }

  p {
    text-align: center;
    position: absolute;
    top: 90%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    animation-duration: 3s;
    animation-name: colorChange1;
    animation-iteration-count: infinite;
    font-family: PressStart, 'Ubuntu', sans-serif;

    @keyframes colorChange1 {
      0% {
        color: #e81b56;
        text-shadow: 0 0 5px #e81b56;
      }

      16% {
        color: #ea760f;
        text-shadow: 0 0 5px #ea760f;
      }

      32% {
        color: #ffbf00;
        text-shadow: 0 0 5px #ffbf00;
      }

      48% {
        color: #21dc3a;
        text-shadow: 0 0 5px #21dc3a;
      }

      64% {
        color: #317fff;
        text-shadow: 0 0 5px #317fff;
      }

      80% {
        color: #8b32f4;
        text-shadow: 0 0 5px #8b32f4;
      }

      100% {
        color: #e81b56;
        text-shadow: 0 0 5px #e81b56;
      }
    }
  }
}

@keyframes colorChange {
  0% {
    border-color: #e81b56;
    box-shadow: 0 0 5px #e81b56;
  }

  16% {
    border-color: #ea760f;
    box-shadow: 0 0 5px #ea760f;
  }

  32% {
    border-color: #ffbf00;
    box-shadow: 0 0 5px #ffbf00;
  }

  48% {
    border-color: #21dc3a;
    box-shadow: 0 0 5px #21dc3a;
  }

  64% {
    border-color: #317fff;
    box-shadow: 0 0 5px #317fff;
  }

  80% {
    border-color: #8b32f4;
    box-shadow: 0 0 5px #8b32f4;
  }

  100% {
    border-color: #e81b56;
    box-shadow: 0 0 5px #e81b56;
  }
}

.slideDown-leave-active,
.slideDown-enter-active {
  transition: all 1s ease-in;
}

.slideDown-enter-from,
.slideDown-leave-to {
  transform: translateY(200vh);
}

.slideUp-leave-active,
.slideUp-enter-active {
  transition: all 1s ease-in;
}

.slideUp-enter-from,
.slideUp-leave-to {
  // opacity: 0;
  transform: translateY(-150vh);
}

.stretch-leave-active,
.stretch-enter-active {
  transition: all 0.5s ease-in;
}

.stretch-enter-from,
.stretch-leave-to {
  opacity: 0;
}
</style>
