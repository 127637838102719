'use strict';

// Imports.
import { mintService } from '../../services';
import { merkleService } from '../../services';

// Specified actions for mutating the alert state.
export default {
  // Use the mint service to get a list of available shop items.
  async getShopItems({ dispatch, commit }, shopAddress) {
    try {
      let response = await mintService.getShopItems(shopAddress);
      commit('updateShop', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the mint service to get all of a user's frens.
  async getBucks({ dispatch, commit }, {}) {
    try {
      let response = await mintService.getOwnedItems();
      commit('updateBucks', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Purchase an item from the mint shop.
  async purchaseItem(
    { dispatch, commit },
    { poolId, groupId, assetId, amount }
  ) {
    console.log(poolId, groupId, assetId, amount);
    try {
      await mintService.purchaseItem(
        poolId,
        groupId,
        assetId,
        amount,
        dispatch
      );
      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async whitelistedPurchaseItem(
    { dispatch, commit },
    { poolId, groupId, amount, userIndex, proofs }
  ) {
    console.log(poolId, groupId, amount);
    try {
      await merkleService.whitelistedPurchaseItem(
        poolId,
        groupId,
        amount,
        userIndex,
        proofs,
        dispatch
      );

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Fetch the merkle proofs for a user
  async getMerkleData({ dispatch, commit }, { groupId }) {
    try {
      let merkleData = await merkleService.getMerkleData();
      console.log("merkleData", merkleData);
      commit('updateProofs', merkleData.userProofs);
      commit('updateIndex', merkleData.index);
      let claimStatus = await merkleService.checkClaim(groupId, merkleData.index);
      commit('updateClaimStatus', claimStatus);
      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },
};
