<template>
  <transition name="fade">
    <ConnectingAnimation v-if="showConnecting" />
  </transition>
  <Ethereum :callback="initialize" />

  <Window
    v-if="openWindows.has('Welcome')"
    @close="handleClose('Welcome')"
    :index="0"
    :title="'welcome.txt'"
    @click="moveToTop"
    class="welcome"
  >
    <Welcome />
  </Window>

  <Window
    v-if="openWindows.has('CLICK HERE')"
    @close="handleClose('CLICK HERE')"
    :index="0"
    :title="'ssh -p 24601 shad0e@192.158.1.3'"
    @click="moveToTop"
  >
    <Manifesto />
  </Window>

  <Window
    v-if="openWindows.has('Scavenger Hunt')"
    @close="handleClose('Scavenger Hunt')"
    :index="0"
    :title="'Scavenger Hunt'"
    @click="moveToTop"
  >
    <ScavengerHunt />
  </Window>

  <Window
    v-if="openWindows.has('Gallery')"
    @close="handleClose('Gallery')"
    :index="1"
    :title="'Gallery'"
    @click="moveToTop"
  >
    <Gallery />
  </Window>

  <Window
    v-if="openWindows.has('Calendar')"
    @close="handleClose('Calendar')"
    :index="2"
    @click="moveToTop"
    :title="'Calendar'"
  >
    <Calendar />
  </Window>

  <Window
    v-if="openWindows.has('FAQ')"
    @close="handleClose('FAQ')"
    :index="3"
    @click="moveToTop"
    :title="'Frequently Asked Questions'"
  >
    <FAQ />
  </Window>

  <Window
    v-if="openWindows.has('Mint')"
    @close="handleClose('Mint')"
    :index="4"
    :title="'Mint a Buck'"
    @click="moveToTop"
  >
    <Mint />
  </Window>

  <Window
    v-if="openWindows.has('Whitelist Presale')"
    @close="handleClose('Whitelist Presale')"
    :index="4"
    :title="'Whitelist Presale'"
    @click="moveToTop"
  >
    <Whitelist />
  </Window>

  <Window
    v-if="openWindows.has('Provenance')"
    @close="handleClose('Provenance')"
    :index="4"
    @click="moveToTop"
    :title="'Provenance Spreadsheet'"
  >
    <Provenance />
  </Window>

  <Window
    v-if="openWindows.has('Journal')"
    @close="handleClose('Journal')"
    :index="4"
    @click="moveToTop"
    :title="'Journal Entries'"
  >
    <Journal />
  </Window>

  <Window
    v-if="openWindows.has('My Clones')"
    @close="handleClose('My Clones')"
    :index="4"
    @click="moveToTop"
    :title="'My Clones'"
  >
    <MyClones />
  </Window>

  <Window
    v-if="openWindows.has('Artwork')"
    @close="handleClose('Artwork')"
    :index="4"
    @click="moveToTop"
    :title="'Artwork'"
  >
    <Artwork />
  </Window>

  <Window
    v-if="openWindows.has('Member')"
    @close="handleClose('Member')"
    :index="4"
    @click="moveToTop"
    :title="'Welcome To The Mob'"
    class="welcome"
  >
    <Member />
  </Window>
  <div :class="store.state.mint.ownsBuck ? 'memberWallpaper' : 'wallpaper'">
    <img
      v-if="store.state.mint.ownsBuck"
      draggable="false"
      class="buck"
      src="../../images/members-only.gif"
    />
    <img
      v-else
      draggable="false"
      class="buck"
      src="../../images/guest-buck-icon.png"
    />
  </div>

  <div class="home">
    <div class="folderContainer">
      <DesktopIcon
        v-for="(item, index) in desktopItems"
        :label="item.label"
        :icon="item.icon"
        :isDisabled="item.disabled"
        :tooltip="item.tooltip"
        :key="item"
        :showArrow="index === 0 && showArrow"
        @click="if (!item.disabled) openWindow(item.label);"
      />
    </div>
  </div>
</template>
<script>
import DesktopIcon from './components/DesktopIcon.vue';
import Window from '/src/components/ui/Window.vue';
import Manifesto from '/src/components/windows/Manifesto.vue';
import Welcome from '/src/components/windows/Welcome.vue';
import Gallery from '/src/components/windows/Gallery.vue';
import Mint from '/src/components/windows/Mint.vue';
import Whitelist from '/src/components/windows/Whitelist.vue';
import Calendar from '/src/components/windows/Calendar.vue';
import Provenance from '/src/components/windows/Provenance.vue';
import FAQ from '/src/components/windows/FAQ.vue';
import Journal from '/src/components/windows/Journal.vue';
import MyClones from '/src/components/windows/MyClones.vue';
import ScavengerHunt from '/src/components/windows/ScavengerHunt.vue';
import ConnectingAnimation from '/src/pages/landing/components/ConnectingAnimation.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import Artwork from '/src/components/windows/Artwork.vue';
import Member from '/src/components/windows/Member.vue';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    Ethereum,
    DesktopIcon,
    Manifesto,
    Welcome,
    Gallery,
    Mint,
    Whitelist,
    Window,
    Calendar,
    Provenance,
    FAQ,
    Member,
    Journal,
    MyClones,
    ScavengerHunt,
    ConnectingAnimation,
    Artwork
  },

  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => {
      return store.state.ethers.address;
    });

    const isManifestoClicked = ref(false);

    const desktopItems = ref([
      {
        label: 'CLICK HERE',
        icon: 'terminal-icon'
      },
      // {
      //   label: 'Whitelist Presale',
      //   icon: 'chrome-icon',
      //   disabled: true,
      //   tooltip: 'Whitelist Closed'
      // },
      {
        label: 'Mint',
        icon: 'chrome-icon'
      },
      {
        label: 'My Clones',
        icon: 'folder-icon'
      },
      {
        label: 'Wallpapers',
        icon: 'photos-logo',
        disabled: true,
        tooltip: 'Coming Soon'
      },
      {
        label: '3D Print',
        icon: 'folder-icon',
        disabled: true,
        tooltip: 'Coming Soon'
      }
    ]);

    const membersOnly = new Set(['Artwork']);

    const isVisible = item => {
      return membersOnly.has(item);
    };

    const showConnecting = ref(true);

    const openWindows = computed(() => {
      return store.state.windows.openWindows;
    });
    const currentWindow = computed(() => {
      return store.state.windows.currentWindow;
    });

    const openWindow = label => {
      if (label === 'CLICK HERE') isManifestoClicked.value = true;
      store.dispatch('windows/open', { label: label });
    };

    const handleClose = label => {
      store.dispatch('windows/close', { label: label });
    };

    const moveToTop = handler => {};

    onMounted(() => {
      setTimeout(() => {
        showConnecting.value = false;
      }, 3000);
      store.state.windows.openWindows.add('Welcome');
      store.state.windows.currentWindow = 'Welcome';
    });

    const isWhitelisted = computed(() => {
      console.log(
        'store.state.mint.merkleProofs',
        store.state.mint.merkleProofs
      );
      return store.state.mint.merkleProofs.length > 0;
    });

    const showArrow = computed(() => {
      return !isManifestoClicked.value;
    });

    const initialize = async () => {
      // If the user is not logged into Metamask, then show the wallet modal.
      if (!isLoggedIn.value) {
        store.dispatch('alert/showWalletModal', { root: true });
      }

      // If the user is logged into Metamask, then check.
      if (isLoggedIn.value) {
        await store.dispatch('mint/getBucks', { root: true });
        await store.dispatch(
          'mint/getMerkleData',
          { groupId: 1 },
          { root: true }
        );
        // TODO: if user is on whitelist here...
        if (isWhitelisted.value) {
          desktopItems.value.forEach(item => {
            if (item.label === 'Whitelist Presale') {
              item.disabled = false;
            }
          });
        }

        // if (store.state.mint.ownsBuck) {
        //   desktopItems.value.forEach(item => {
        //     if (item.label === 'Artwork') {
        //       item.disabled = false;
        //     }
        //   });
        // }
      }
    };

    watch(
      () => store.state.ethers.address,
      selection => {
        initialize();
      }
    );

    watch(
      () => store.state.ethers.networkId,
      selection => {
        initialize();
      }
    );

    return {
      desktopItems,
      currentWindow,
      openWindow,
      openWindows,
      handleClose,
      moveToTop,
      isLoggedIn,
      showConnecting,
      initialize,
      membersOnly,
      isVisible,
      showArrow,
      store,
      isWhitelisted
    };
  }
};
</script>
<style scoped lang="scss">
.welcome {
  height: 400px;
  // min-height: 49%;
  width: 50%;
  top: 20%;
  left: 20%;
}

.wallpaper {
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100vw;

  background: url('../../images/wallpaper-member.gif') no-repeat center center
    fixed;
  background-image: radial-gradient(#003d56, #23313b);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .buck {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    height: 100%;
    width: 100%;
    max-height: 300px;
    max-width: 300px;
  }
}

.memberWallpaper {
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100vw;

  background: url('../../images/wallpaper-member.gif') no-repeat center center
    fixed;
  background-image: radial-gradient(#9b0096, #470045);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .buck {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    height: 100%;
    width: 100%;
    max-height: 300px;
    max-width: 300px;
  }
}

.home {
  padding-top: 60px;
  width: 100%;
  background-color: transparent;
  margin-left: auto;
  .folderContainer {
    position: absolute;
    right: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
}

// Animatiions
/* Fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: all 300ms ease-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
