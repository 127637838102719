<template>
  <div class="calendarEvent">
    <div class="left" :style="sideColor"></div>
    <div class="right" :style="backgroundColor">
      <p :style="textColor">{{ data.title }}</p>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    data: Object,
    isClicked: Boolean
  },

  setup(props) {
    const backgroundColor = computed(() => {
      if (props.isClicked) {
        return { backgroundColor: `rgba(${props.data.color}, 0.7)` };
      } else {
        return { backgroundColor: `rgba(${props.data.color}, 0.3)` };
      }
    });

    const sideColor = computed(() => {
      return {
        backgroundColor: `rgba(${props.data.color}, 1)`
      };
    });

    const textColor = computed(() => {
      if (props.isClicked) {
        return { color: `rgba(255,255,255, 1)` };
      } else {
        return { color: `rgba(${props.data.color}, 1)` };
      }
    });

    return { backgroundColor, sideColor, textColor };
  }
};
</script>
<style scoped lang="scss">
.calendarEvent {
  display: flex;
  .left {
    width: 5px;
  }

  .right {
    width: 100%;
    height: 150px;
    padding: 10px;
  }
}
</style>
