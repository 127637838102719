<template>
  <div class="faq">
    <div class="title">Questions People Would Probably Ask Me</div>
    <div class="faqContainer">
      <FAQItem
        v-for="question in questions"
        :title="question.title"
        :answer="question.answer"
        :key="question"
      />
    </div>
  </div>
</template>
<script>
import FAQItem from '/src/components/windows/FAQ/FAQItem.vue';
export default {
  components: {
    FAQItem
  },

  setup() {
    const questions = [
      {
        title: 'Why the name "Bootleg Buck Mob"?',
        answer: [
          '"Bootleg" refers to the clothing worn by my clones. My closet is filled with bootleg clothes from the 2000’s',
          '"Buck" cuz the clones got influenced by my subconscious mind after I watched the 2021 NBA finals Hardwood Classics stream (+ I’ve been a bucks fan since i was 12)',
          '"Mob" is often used to describe a group of deer'
        ]
      },
      {
        title: 'What is the Bootleg Buck Mob?',
        answer: [
          'A community of my clones as NFTs. This community will go beyond digital ownership of goods and hopefully extend to a family of  like-minded people that have similar interests & taste.'
        ]
      },

      {
        title: 'What type of content are you gonna be creating?',
        answer: [
          'I’m gonna be focused on creating content that is centered around 90’s, early 2000’s, and present street/skate culture. Some of my favorite brands/designers include The Hundreds, Warren Lotas, Kith, & most 90’s skate brands. I am a visual artist + streetwear designer and am always inspired by things from the past and around me.'
        ]
      },
      {
        title: 'Which network will you launch on?',
        answer: ['Bootleg Buck Mob is an Ethereum-based NFT collection.']
      },
      {
        title: 'How many Bucks can I have?',
        answer: ['There is no limit on how many your wallet can hold.']
      },
      {
        title: 'How do I get on the whitelist?',
        answer: [
          'Details surrounding the whitelist and how to qualify can be found in the 🚨｜announcements channel in our discord.'
        ]
      },

      {
        title: 'What is the official drop date?',
        answer: ['November 4th, 2PM PST']
      }
    ];

    return {
      questions
    };
  }
};
</script>
<style scoped lang="scss">
.faq {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  overflow-y: scroll;
  padding-bottom: 50px;
  .title {
    font-size: 28px;
    color: rgb(46, 99, 255);
    margin-bottom: 20px;
  }
}
</style>
