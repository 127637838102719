<template>
  <div class="walletModal__overlay" @click="exitModal"></div>
  <transition name="slide" appear>
    <div class="modal">
      <p>Shad0e's VPN Login</p>
      <div class="accountWrapper">
        <div class="account" @click="exitModal">
          <img src="../../images/guest-icon.png" />
          <p>Guest</p>
        </div>
        <div class="account" @click="handleMetaMask">
          <img src="../../images/metamask-icon.png" />
          <p>MetaMask</p>
        </div>
      </div>
      <div class="footer">
        <p>Welcome to the Bootleg Buck Mob!</p>
        <p>Connect to the Ethereum Mainnet via MetaMask or sign in as Guest.</p>
      </div>
    </div>
  </transition>
</template>

<script>
'use strict';

// Imports.
import { mapState, useStore } from 'vuex';
import { ref } from 'vue';
import store from '../../store';

// Set up the default component.
export default {
  emits: ['exit'],

  data() {
    return {
      show: true
    };
  },

  setup() {
    const store = useStore();
    const password = ref('');

    const pw = 'glitchbuck2999';
    const incorrectPassword = ref(false);

    const checkPassword = () => {
      if (password.value === pw) {
        store.state.login.isLoggedIn = true;
      } else {
        incorrectPassword.value = true;
      }
    };

    const navigateTo = url => {
      window.open(url, '_blank');
    };

    return {
      password,
      checkPassword,
      incorrectPassword,
      navigateTo,
      store
    };
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers
    })
  },

  methods: {
    async handleMetaMask() {
      if (this.ethereum.hasLocalProvider) {
        await store.dispatch('ethers/connectWallet', '', { root: true });
        this.$emit('exit');
        // If there is no local Ethereum provider, this button should direct the
        // user to install MetaMask.
      } else {
        window.open('https://www.metamask.io', '_blank');
      }
    },
    exitModal() {
      this.$emit('exit');
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  .enterPassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .title {
      font-size: 28px;
      animation: rainbowText infinite 7s;
      font-family: PressStart;
      margin-bottom: 30px;
    }

    .textInput {
      border: none;
      border-radius: 10px;
      background: transparent;
      outline: none;
      color: white;
      border: 2px solid white;
      padding: 20px;
      font-size: 24px;
      font-weight: bold;
      width: 400px;
      text-align: center;
      margin-bottom: 20px;

      &:hover {
        animation: rainbowText infinite 4s, rainbowBorder infinite 5s;
      }
    }

    button {
      height: 100%;
      font-size: 18px;
      font-family: PressStart;
      animation-duration: 10s;
      animation-name: colorChange1;
      animation-iteration-count: infinite;
      width: fit-content;
      padding: 20px;
      border-radius: 10px;
      border: 2px solid white;
      transition: all 200ms ease-in;
      cursor: pointer;
      color: white;
      animation-name: rainbowText, rainbowBorder;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      background-color: rgba(0, 0, 0, 0.6);

      &:hover {
        animation-name: rainbowBackground;
      }

      &:active {
        animation-name: none;
        background-color: rgb(255, 0, 191);
      }
    }

    .error {
      color: red;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 20px;
    }

    .forgot {
      font-size: 20px;
      margin: 30px;
      transition: all ease-in 200ms;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        animation: rainbowText infinite 4s;
      }
    }
  }

  .accountWrapper {
    display: flex;
    justify-content: center;

    .account {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      align-self: center;
      justify-self: center;
      padding: 30px;
      border-radius: 20px;
      transition: all ease-in 200ms;
      cursor: pointer;
      margin: 30px;
      img {
        height: 70px;
        width: 70px;
        object-fit: contain;
        margin-bottom: 20px;
      }
      p {
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .footer {
    text-align: center;
    opacity: 0.6;
    font-weight: normal;

    p {
      margin-bottom: 10px;
    }
  }
}
.walletModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  backdrop-filter: blur(40px);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal > p {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 600;
  text-align: center;
}

.wallet-provider {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  background-color: black;
  transition: box-shadow 200ms ease;
  font-size: 16px;
  font-weight: 500;
  color: #f6851a;
  height: 70px;
  padding: 15px 25px;
}

.wallet-provider:hover {
  cursor: pointer;
  box-shadow: 0 8px 15px 0 rgba(255, 255, 255, 0.25);
}

.wallet-provider:active {
  box-shadow: none;
}

.slide-enter-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-active {
  opacity: 0;
}
</style>
