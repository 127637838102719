<template>
  <div class="rarity">
    <animated-component animationType="left">
      <h1 class="header">Rarity</h1>
    </animated-component>
    <animated-component>
      <div class="rarityWrapper">
        <div class="messageWrapper">
          <div class="message">
            <div v-for="(trait, index) in traits" :key="index" class="traitRow">
              <div class="value" :class="`text-${index}`">
                {{ trait.value }}
              </div>
              <div class="label">{{ trait.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </animated-component>
    <animated-component>
      <div class="imageContainer">
        <div class="imageWrapper">
          <img src="../../../images/gifs/tv1.gif" />
        </div>
        <div class="imageWrapper">
          <img src="../../../images/gifs/tv7.gif" />
        </div>
        <div class="imageWrapper">
          <img src="../../../images/gifs/tv8.gif" />
        </div>
        <div class="imageWrapper">
          <img src="../../../images/gifs/tv6.gif" />
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script>
export default {
  setup() {
    const traits = [
      {
        label: 'TOTAL',
        value: '5,555'
      },
      {
        label: 'UNIQUE TRAITS',
        value: '292'
      },

      {
        label: 'Backgrounds',
        value: 20
      },
      {
        label: 'Furs',
        value: 18
      },
      {
        label: 'Earrings',
        value: 10
      },
      {
        label: 'Antlers',
        value: 20
      },
      {
        label: 'Clothing Items',
        value: 95
      },

      {
        label: 'Mouths',
        value: 50
      },
      {
        label: 'Eyes',
        value: 34
      },
      {
        label: 'Headwear',
        value: 45
      },
      {
        label: 'super rare (1 of 1 bucks)',
        value: 9
      }
    ];

    return {
      traits
    };
  }
};
</script>
<style scoped lang="scss">
.rarity {
  margin: auto;
  max-width: 1100px;
  .header {
    font-family: CooperBlackOutline;
    font-size: 50px;
    font-weight: lighter;
    color: rgb(255, 0, 140);
    text-shadow: 0 0 5px rgb(255, 0, 140);

    margin-bottom: 20px;
  }
  .imageContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    gap: 2%;
    .imageWrapper {
      flex: 1;
      img {
        border-radius: 10px;
        width: 100%;
        border: 3px solid white;
        box-shadow: 0 0 10px white;
        animation-name: rainbowBorder;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        transition: all 1s ease-in;

        &:hover {
          animation-name: none;
          box-shadow: 0px 0px 20px white;
        }
      }
    }
  }

  .rarityWrapper {
    .messageWrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .name {
        text-align: center;
        color: lime;
        text-shadow: 0 0 5px lime;
        margin-top: 20px;
      }
      img {
        border: 1px solid rgb(255, 196, 0);
        box-shadow: 0 0 15px rgb(255, 196, 0);
        // padding: 10px;
        margin-right: 20px;
        height: 200px;
        width: 200px;
        border-radius: 10px;
      }
      .message {
        border: 3px solid white;
        box-shadow: 0 0 10px white;
        border-radius: 10px;
        padding: 20px;
        width: 100%;

        .traitRow {
          display: flex;
          border-bottom: 2px dotted white;
          justify-content: space-between;
          font-size: 20px;
          font-weight: normal;
          text-align: left;
          // font-style: italic;
          // font-family: CooperBlack;
          color: white;
          text-shadow: 0 0 5px white;
          padding: 10px 0px;
          margin-bottom: 10px;

          .label {
            font-style: italic;
            text-transform: uppercase;
          }

          .value {
            font-size: 24px;
            font-family: PressStart;
            animation-duration: 10s;
            animation-name: colorChange1;
            animation-iteration-count: infinite;
            @keyframes colorChange1 {
              0% {
                color: #e81b56;
                text-shadow: 0 0 5px #e81b56;
              }

              16% {
                color: #ea760f;
                text-shadow: 0 0 5px #ea760f;
              }

              32% {
                color: #ffbf00;
                text-shadow: 0 0 5px #ffbf00;
              }

              48% {
                color: #21dc3a;
                text-shadow: 0 0 5px #21dc3a;
              }

              64% {
                color: #317fff;
                text-shadow: 0 0 5px #317fff;
              }

              80% {
                color: #8b32f4;
                text-shadow: 0 0 5px #8b32f4;
              }

              100% {
                color: #e81b56;
                text-shadow: 0 0 5px #e81b56;
              }
            }
          }

          .text-0 {
            color: rgb(255, 0, 0);
          }
        }
        .subtext {
          font-size: 20px;
          font-weight: normal;
          text-align: left;
          // font-style: italic;
          // font-family: CooperBlack;
          color: white;
          text-shadow: 0 0 5px white;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .rarity {
    .header {
      text-align: center;
      margin-bottom: 30px;
    }
    .rarityWrapper {
      flex-direction: column-reverse;

      .imageWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;

        img {
          margin-bottom: 10px;
          max-height: 100%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .rarity .imageContainer {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
