<template>
  <div class="mint">
    <animated-component animationType="left">
      <h1 class="header">Mint</h1>
    </animated-component>
    <div class="mintWrapper">
      <div class="imageWrapper">
        <img src="../../../images/unrevealed.gif" />
      </div>
      <div class="infoWrapper">
        <div class="mainnet" v-if="store.state.ethers.networkId !== '0x1'">
          Please Switch Network to Ethereum Mainnet
        </div>
        <button
          v-if="!store.state.ethers.canSign"
          class="connectWallet"
          @click="connectWallet"
        >
          Connect Wallet
        </button>
        <p v-else class="address">
          Logged in as: <span>{{ formattedAddress }}</span>
        </p>
        <div>
          <p>Price: <span>0.06 ETH</span> per NFT + Gas</p>
          <p>Limit: <span>10 NFTs</span> per Transaction</p>
        </div>
        <div class="buyWrapper">
          <DropdownMenu
            class="dropdown"
            :selection="number"
            :items="numbers"
            @selected="handleSelection(index, $event)"
          />
          <button
            @click="purchaseItem"
            :class="store.state.ethers.canSign ? '' : 'disabled'"
          >
            Mint
          </button>
        </div>
        <p class="note">Note: Use Metamask wallet for minting</p>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownMenu from '/src/components/ui/MintPageDropdownMenu.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { formatAddress } from '/src/utility/format';

export default {
  components: {
    DropdownMenu
  },

  setup() {
    const store = useStore();
    const number = ref(1);
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const handleSelection = (index, selection) => {
      number.value = selection;
    };

    const connectWallet = async () => {
      console.log(store.state.ethers);
      if (store.state.ethers.hasLocalProvider) {
        await store.dispatch('ethers/connectWallet', '', { root: true });
      } else {
        window.open('https://www.metamask.io', '_blank');
      }
    };

    const formattedAddress = computed(() => {
      return formatAddress(store.state.ethers.address);
    });

    const purchaseItem = async () => {
      if (store.state.ethers.canSign) {
        await store.dispatch(
          'mint/purchaseItem',
          {
            poolId: 0,
            groupId: 1,
            assetId: 0,
            amount: number.value
          },
          { root: true }
        );
      }
    };

    return {
      store,
      number,
      numbers,
      handleSelection,
      connectWallet,
      formattedAddress,
      purchaseItem
    };
  }
};
</script>
<style scoped lang="scss">
.mint {
  margin: auto;
  max-width: 1100px;

  .header {
    font-family: CooperBlackOutline;
    font-size: 50px;
    font-weight: lighter;
    color: #00ffaa;
    text-shadow: 0 0 5px #00ffaa;
    margin-bottom: 20px;
  }

  .mintWrapper {
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .imageWrapper {
      max-height: 300px;
      max-width: 300px;
      border: 2px solid white;
      box-shadow: 0 0 10px white;
      border-radius: 5px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 5px;
      }
    }
    .infoWrapper {
      margin: 30px;
      text-transform: uppercase;

      font-style: italic;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .mainnet {
        background-color: red;
        color: white;
        padding: 10px;
        text-align: center;
        margin-bottom: 10px;
      }
      .note {
        margin-top: 20px;
        font-size: 14px;
        text-align: center;
      }
      p {
        margin-bottom: 10px;
        font-size: 20px;
        text-shadow: 0 0 5px white;

        span {
          color: #00ffaa;
          text-shadow: 0 0 5px #00ffaa;
        }
      }
      .buyWrapper {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        width: 100%;
        align-items: center;

        button {
          height: 100%;
          width: 100%;
          color: #00ff42;
          outline: none;
          cursor: pointer;
          border: 3px solid #00ff42;
          background: none;
          text-transform: uppercase;
          transition: all 200ms ease-in;

          &:hover {
            background-color: #00ff42;
            color: black;
          }
        }

        .disabled {
          cursor: not-allowed;
          border-color: grey;
          background-color: grey;
          color: white;

          &:hover {
            background-color: grey;
            color: white;
          }
        }
      }
      .connectWallet {
        height: 100%;
        padding: 10px;
        margin-bottom: 30px;
        color: #00ff42;
        outline: none;
        cursor: pointer;
        border: 3px solid #00ff42;
        background: none;
        text-transform: uppercase;
        transition: all 200ms ease-in;

        &:hover {
          background-color: #00ff42;
          color: black;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .mint .mintWrapper {
    flex-direction: column;
  }

  .mint .header {
    text-align: center;
  }

  .mint .mintWrapper .infoWrapper .buyWrapper {
    display: flex;
    flex-direction: column;

    .dropdownMenu {
      width: 100%;
    }

    button {
      margin-top: 10px;
      padding: 15px;
    }
  }
}
</style>
