<template>
  <LoadingAnimation />
  <Ethereum :callback="initialize" />

  <transition name="slide" appear>
    <Header />
  </transition>

  <div class="landingPage">
    <div class="splashContainer">
      <div class="top">
        <div class="header">
          <div class="wrapper">
            <!-- <p class="rainbowText">Welcome to the Bootleg Buck Mob!</p> -->
            <!-- <img src="../../images/buck-mob-logo-red.svg" /> -->
            <!-- <p class="rainbowText small">
              Welcome! Click below to enter a web portal into the year 2999
            </p> -->
            <div class="mobile">
              <p class="rainbowText">
                Welcome to the
              </p>
              <p class="rainbowText">
                Bootleg Buck Mob!
              </p>
            </div>
          </div>
          <!-- <div class="line"></div> -->

          <div class="lineContainer">
            <!-- <div class="line"></div>
            <div class="line"></div> -->
          </div>
        </div>
      </div>
      <div class="tvGrid">
        <div class="left">
          <TVMonitor class="monitor" color="red" :index="1" />
          <TVMonitor class="monitor" color="yellow" :index="2" />
          <TVMonitor class="monitor" color="blue" :index="3" />
        </div>
        <div class="middle">
          <MainMonitor class="monitor" @enter="handleEnter" />
        </div>
        <div class="right">
          <TVMonitor class="monitor" color="purple" :index="4" />
          <TVMonitor class="monitor" color="orange" :index="5" />
          <TVMonitor class="monitor" color="green" :index="6" />
        </div>
      </div>
      <MobileMonitor class="mobileMonitor" />
    </div>

    <div class="divider"></div>

    <div class="information">
      <Mint class="section" />

      <div class="wrapper section">
        <div class="messageWrapper">
          <div class="shadoe">
            <img src="../../images/buck-icon.svg" />
            <p class="name">[ SHAD0E ]</p>
          </div>

          <div class="message">
            <p class="subtext">Yo! <span>My name is SHAD0E</span>,</p>
            <p class="subtext">
              >> I am an <span>artist/vintage designer</span> from the year
              <span>2999</span>.
            </p>
            <p class="subtext">
              >> Where I'm from, we're not allowed to view, purchase, and create
              artwork that <span>strays from the norm</span>.
            </p>

            <p class="subtext">
              >> So I'm traveling <span>back in time</span> to create content
              for you, hoping to trigger a butterfly effect that
              <span>saves the art scene in the future</span>.
            </p>

            <p class="subtext">
              >> My goal is to cultivate an
              <span>open community of likeminded people</span>
              interested in art, streetwear, and dope shit in general. A
              community so strong that by the time 2999 comes around,
              <span>creative expression won't be a thing of the past</span>.
            </p>

            <p class="subtext">
              >> I will do this through NFTs that I have created by cloning
              myself from this machine I got my hands on.
              <span>Holders of my NFTs will be part of the mob</span>, which
              means you'll get <span>exclusive access</span> to content I create
              & <span>admin credentials to my computer</span>.
            </p>
            <p class="subtext">
              >> Btw, <span>I’m not actually a deer</span>, my cloning machine
              is just a little fucked up xD
            </p>
          </div>
        </div>
        <Collection class="section" />
        <Rarity class="section" />
        <Roadmap class="section" />
        <About class="section" />
      </div>
      <Footer class="section" />
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import TVMonitor from './components/TVMonitor.vue';
import MainMonitor from './components/MainMonitor.vue';
import Collection from './components/Collection.vue';
import Rarity from './components/Rarity.vue';
import About from './components/About.vue';
import Roadmap from './components/Roadmap.vue';
import Header from './components/Header.vue';
import Mint from './components/Mint.vue';
import Footer from './components/Footer.vue';
import MobileMonitor from './components/MobileMonitor.vue';
import Ethereum from '/src/components/common/Ethereum.vue';

import LoadingAnimation from './components/LoadingAnimation.vue';
import Countdown from '../../components/ui/Countdown.vue';

export default {
  components: {
    TVMonitor,
    MainMonitor,
    Rarity,
    Collection,
    About,
    Roadmap,
    Header,
    Footer,
    MobileMonitor,
    LoadingAnimation,
    Mint,
    Ethereum
  },

  setup() {
    const router = useRouter();
    const loadAnimation = ref(false);

    const handleEnter = () => {
      router.push({ name: 'Home' });
    };

    onMounted(() => {
      setTimeout(() => {
        loadAnimation.value = true;
      }, 4000);
    });

    const initialize = () => {};

    const startDate = new Date(2021, 10, 3, 14);

    return {
      router,
      handleEnter,
      loadAnimation,
      initialize,
      startDate
    };
  }
};
</script>
<style scoped lang="scss">
// Animations.
.slide-enter-active,
.slide-leave-active {
  transition: all 500ms ease-out;
  transform: translateY(0px);
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.landingPage {
  min-height: 100vh;
  width: 100%;
  .splashContainer {
    background-image: radial-gradient(rgb(59, 59, 59), rgb(10, 10, 10));

    .mobileMonitor {
      display: none;
    }

    .top {
      padding-top: 50px;

      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        text-transform: uppercase;
        font-size: 20px;
        // font-style: italic;
        color: white;
        text-shadow: 0 0 5px white;
        // display: flex;
        align-items: center;
        align-items: flex-end;
        width: fit-content;
        text-align: center;
        // margin-bottom: 40px;
        // border: 3px solid white;
        border-radius: 50px;
        animation-duration: 7s;
        // animation-name: colorChange;
        animation-iteration-count: infinite;

        .mobile {
          display: none;
        }

        .rainbowText {
          color: white;
          text-shadow: 0 0 5px white;
          // animation-duration: 5s;
          // animation-name: colorChange1;
          // animation-iteration-count: infinite;
          font-family: PressStart;
          // margin-bottom: 10px;
          padding: 20px;

          @keyframes colorChange1 {
            0% {
              color: #e81b56;
              text-shadow: 0 0 5px #e81b56;
            }

            16% {
              color: #ea760f;
              text-shadow: 0 0 5px #ea760f;
            }

            32% {
              color: #ffbf00;
              text-shadow: 0 0 5px #ffbf00;
            }

            48% {
              color: #21dc3a;
              text-shadow: 0 0 5px #21dc3a;
            }

            64% {
              color: #317fff;
              text-shadow: 0 0 5px #317fff;
            }

            80% {
              color: #8b32f4;
              text-shadow: 0 0 5px #8b32f4;
            }

            100% {
              color: #e81b56;
              text-shadow: 0 0 5px #e81b56;
            }
          }
        }

        .small {
          font-size: 12px;
        }

        p {
          display: block;
          white-space: nowrap;
          margin: 0px 10px;
        }

        .line {
          width: 100%;
          border-bottom: 3px dotted white;
          text-shadow: 0 0 3px white;
          animation-duration: 5s;
          animation-name: colorChange;
          animation-iteration-count: infinite;
          margin-bottom: 5px;
        }

        .lineContainer {
          width: 100%;
          display: grid;
          grid-template-columns: auto;
          // grid-row-gap: 7px;

          .line {
            width: 100%;
            border-bottom: 2px solid white;
            text-shadow: 0 0 3px white;
            animation-duration: 5s;
            animation-name: colorChange;
            animation-iteration-count: infinite;
            margin-bottom: 5px;

            @keyframes colorChange {
              0% {
                border-color: #e81b56;
                box-shadow: 0 0 5px #e81b56;
              }

              16% {
                border-color: #ea760f;
                box-shadow: 0 0 5px #ea760f;
              }

              32% {
                border-color: #ffbf00;
                box-shadow: 0 0 5px #ffbf00;
              }

              48% {
                border-color: #21dc3a;
                box-shadow: 0 0 5px #21dc3a;
              }

              64% {
                border-color: #317fff;
                box-shadow: 0 0 5px #317fff;
              }

              80% {
                border-color: #8b32f4;
                box-shadow: 0 0 5px #8b32f4;
              }

              100% {
                border-color: #e81b56;
                box-shadow: 0 0 5px #e81b56;
              }
            }
          }
        }
      }
      img {
        width: 100%;
        max-width: 400px;
      }
    }

    .tvGrid {
      display: flex;
      justify-content: center;

      .left {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }
      .right {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }

      .middle {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // margin: 0px -80px;

        .monitor {
          align-self: flex-end;
        }
      }
      .monitor {
        margin-bottom: -20px;
      }
    }
  }

  .divider {
    height: 200px;
    width: 100%;
    margin-top: -100px;
    background-image: linear-gradient(rgb(34, 34, 34), rgb(10, 10, 10));
    border-top: 3px solid rgba(255, 255, 255, 0.2);
  }

  .information {
    background-image: linear-gradient(rgb(10, 10, 10), rgb(0, 0, 0));
    .presaleWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      .presale {
        color: white;
        text-shadow: 0 0 5px white;
        font-style: italic;
        text-transform: uppercase;
        font-size: 22px;
        margin-bottom: 10px;
      }
    }

    .section {
      padding: 40px 30px;
    }

    .wrapper {
      margin: auto;
      max-width: 1100px;
      .header {
        font-family: CooperBlackOutline;
        font-size: 50px;
        font-weight: lighter;
        text-align: center;

        color: lime;
        text-shadow: 0 0 5px lime;

        margin-bottom: 20px;
      }

      .logo {
        display: flex;
        justify-content: center;
        img {
          width: 40%;
          max-width: 300px;
          height: 100%;
          object-fit: contain;
        }
      }

      .messageWrapper {
        display: flex;
        align-items: center;
        .shadoe {
          margin-right: 20px;
          .name {
            text-align: center;
            color: lime;
            text-shadow: 0 0 5px lime;
            margin-top: 20px;
          }
          img {
            // padding: 10px;

            height: 200px;
            width: 200px;
            border-radius: 10px;
          }
        }

        .message {
          border: 3px solid white;
          box-shadow: 0 0 10px white;
          border-radius: 5px;
          padding: 20px;
          .subtext {
            font-size: 20px;
            font-weight: normal;
            text-align: left;
            // font-style: italic;
            // font-family: CooperBlack;
            color: white;
            text-shadow: 0 0 5px white;
            margin-bottom: 20px;

            span {
              animation: rainbowText 3s infinite;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .landingPage .splashContainer .mobileMonitor {
    display: initial;
  }

  .landingPage .splashContainer .tvGrid .middle {
    display: none;
  }

  .landingPage .splashContainer .tvGrid .right {
    display: none;
  }

  .landingPage .splashContainer .tvGrid .left {
    display: none;
  }

  .landingPage .splashContainer .top .header {
    font-size: 14px;
    max-width: 95%;
    padding: 10px;
    display: none;
  }

  .landingPage .splashContainer .top .header .rainbowText {
    display: none;
  }

  .landingPage .splashContainer .top .header .mobile {
    display: grid;
    grid-row-gap: 15px;
    .rainbowText {
      display: initial;
      padding: 0px;
    }
    flex-direction: column;
    display: flex;
  }

  .landingPage .information .wrapper .logo img {
    width: 100%;
    max-width: 300px;
  }
  .landingPage .information .wrapper .messageWrapper {
    margin: auto;
  }
  .landingPage .information .wrapper .messageWrapper img {
    width: 90%;
    height: 100%;
    max-width: 400px;
  }

  .landingPage .information .wrapper .messageWrapper .shadoe {
    margin: 0px;
  }

  .landingPage .divider {
    height: 75px;
  }

  .landingPage .information .wrapper .messageWrapper .shadoe .name {
    margin: 0px;
    margin-bottom: 10px;
  }

  .landingPage .splashContainer .top {
    padding-top: 30px;
  }
}

@media (max-width: 950px) {
  .landingPage .information .wrapper .messageWrapper {
    .message {
      margin-top: 30px;
    }
    flex-direction: column;
  }

  .landingPage .information .wrapper .header {
    margin-bottom: 30px;
  }
}
</style>
